import React from 'react'
import { useNavigate } from 'react-router-dom'

import { OrderMode } from '@src/../__generated__/globalTypes'
import { LocationType } from '@src/graphql-types'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { convertToSlug } from '@src/utils/convertToSlug'

import { HomePostcodeInput } from './HomePostcodeInput'
import { Container, StyledDeliveryZoneInput } from './LocationInput.styles'

export const LocationInput: React.FC = () => {
  const { orderMode, urlPath } = useMarketplace()
  const navigate = useNavigate()
  const fulfilmentFilter = useFulfilmentFilter()

  return (
    <Container>
      {orderMode === OrderMode.LIST ? (
        <StyledDeliveryZoneInput
          selectedDeliveryZoneId={
            fulfilmentFilter.data.where.historicalData.zoneId
          }
          onSubmit={zone => {
            fulfilmentFilter.setLocationType({
              type: LocationType.DELIVERY_ZONE,
              zoneId: zone.id,
            })
            navigate(`/${convertToSlug(zone.name)}/${urlPath}`)
          }}
        />
      ) : (
        <HomePostcodeInput />
      )}
    </Container>
  )
}
