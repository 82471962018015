import React, { useEffect, createContext } from 'react'

import { useOutletFulfilmentData } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilmentData/useOutletFulfilmentData'
import { useBasketOutletId } from '@src/hooks/useBasketOutletId'
import { parseOutletDates } from '@src/utils/fulfilmentTimes/parsers'

import { OutletFulfilmentContext } from './types'
// context used to store fetched current basket's outlet's data
// shares the same type as the CurrentOutletContext
export const BasketOutletContext = createContext<OutletFulfilmentContext>(null)

// Provider which fetches the data for the outlet in the basket
// and stores it in the context for use in the useBasket hook
// Renders the EmptyBasket component if the basket is empty (ie basketOutletId is null)
export const BasketOutletProvider: React.FC<{
  basketOutletIdOverride?: string
  NoBasketComponent?: React.ReactNode
}> = ({ children, basketOutletIdOverride, NoBasketComponent = null }) => {
  const { basketOutletId, updateBasketOutletId } = useBasketOutletId()

  const outletId = basketOutletIdOverride || basketOutletId

  const { data, loading, error } = useOutletFulfilmentData({
    outletId: outletId || undefined,
    showInactiveOverride: true,
  })

  // basket outlet doesn't exist, reset reactive var to null to display empty basket instead
  useEffect(() => {
    if (outletId && !loading && !data?.outlet) {
      console.error('Outlet not found')
      updateBasketOutletId(null)
    }
  }, [loading, data?.outlet, error, updateBasketOutletId, outletId])

  if (loading) {
    return null
  }

  if (!outletId) {
    return <>{NoBasketComponent}</>
  }

  if ((!data && outletId) || error) {
    // TODO: Better error component
    return <>{'Error'}</>
  }

  const outletFulfilmentContextData = data?.outlet
    ? parseOutletDates(data.outlet)
    : null

  return (
    <BasketOutletContext.Provider
      value={
        outletFulfilmentContextData
          ? { outlet: outletFulfilmentContextData }
          : null
      }
    >
      {children}
    </BasketOutletContext.Provider>
  )
}
