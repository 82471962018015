import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export const Redirect: React.FC<{
  passthroughQueryParams?: boolean
  to: string | { pathname: string; state?: any; search?: string }
}> = ({ passthroughQueryParams = true, to }) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    // Extract pathname and search (query parameters)
    const [pathname, search] =
      typeof to === 'string' ? to.split('?') : [to.pathname, to.search]

    // Construct the final path and search
    const finalPath = {
      pathname,
      search: passthroughQueryParams ? location.search : search || '',
      ...(typeof to !== 'string' && to), // Retain other properties like `state`
    }

    // Navigate to the new path
    navigate(finalPath.pathname + finalPath.search, {
      replace: true,
      state: finalPath.state,
    })
  }, [navigate, passthroughQueryParams, to, location.search])

  return null // Since navigation happens in `useEffect`, no need to render anything.
}
