import { CustomerLoyaltyCard } from '@src/components/CustomerLoyaltyCard/CustomerLoyaltyCard'
import {
  Address,
  Table,
  Outlet,
  DeliveryPreorderWindow,
  AvailableFulfilment,
} from '@src/graphql-types'

export interface Cuisine {
  id: string
  name: string
}

export interface Discount {
  id: string
  name: string
  customerEnrolled: boolean
  discountAmount: number | null
  discountPercentage: number | null
  minimumSubtotalGross: number | null
}

export interface Restaurant {
  id: string
  image: string | null
  name: string
  allDiscounts?: Discount[]
}

export interface LoyaltyCardStamp {
  id: string
}

export interface LoyaltyCard {
  id: string
  loyaltyCardColor: string | null
  loyaltyCardIcon: string | null
  requiredStamps: number
  loyaltyCardStamps: LoyaltyCardStamp[] | null
  discount: { id: string; name: string }
}

export type SingleOutlet = Pick<
  Outlet,
  | 'id'
  | 'name'
  | 'description'
  | 'isOpen'
  | 'isOnline'
  | 'isOrderable'
  | 'openingTimesArray'
  | 'paymentMethods'
  | 'deliveryMinimumOrderValue'
  | 'collectionMinimumOrderValue'
  | 'asapAllowed'
  | 'defaultCollectionTime'
  | 'ASAPDeliveryDuration'
  | 'prepTime'
  | 'coverImage'
  | 'allowPreorders'
  | 'outletLogoOverride'
  | 'distanceFromUserKM'
  | 'daysOfferedInAdvanceMin'
  | 'daysOfferedInAdvanceMax'
  | 'daysOfferedInAdvanceMinDelivery'
  | 'daysOfferedInAdvanceMaxDelivery'
  | 'daysOfferedInAdvanceMinCollection'
  | 'daysOfferedInAdvanceMaxCollection'
  | 'availableFulfillmentMethods'
  | 'statusText'
  | 'availableFulfilmentInputMethods'
  | 'noPreordersBeforeOpening'
  | 'displayName'
  | 'fulfilmentRange'
  | 'specialOfferFlags'
  | 'outletCuisines'
  | 'promoteOutlet'
> & {
  __typename?: string
  restaurant: Restaurant

  tables: Table[]
  outletAddress?: Omit<Address, 'country' | 'acceptsDelivery'>
  nextOpenDate: string | undefined
  closedUntil: string | undefined
  outletAllDiscounts: Discount[]
  outletLoyaltyCards: CustomerLoyaltyCard[]
  availableFulfilments: AvailableFulfilment[]
}

export type SingleOutletWithDeliveryWindows = SingleOutlet & {
  deliveryPreorderWindows: DeliveryPreorderWindow[]
  collectionPreorderTimes: string[]
}

export enum SingleOutletDirection {
  'ROW' = 'row',
  'COLUMN' = 'column',
}
