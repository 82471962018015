import { omit } from 'lodash'

import {
  FulfilmentTimeType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import { ALL_FULFILMENT_METHODS_SORTED } from '@src/utils/fulfilment'

import { FulfilmentFilter, FulfilmentFilterWhenType } from '../validation'

export const extendFulfilmentFilterData = (
  fulfilmentFilterData: FulfilmentFilter
) => {
  return {
    ...fulfilmentFilterData,
    isExtended: true,
    get priorityFulfilmentMethod(): NarrowFulfilmentMethodInputType {
      if (
        fulfilmentFilterData.where.fulfilmentMethods.includes(
          NarrowFulfilmentMethodInputType.DELIVERY
        )
      ) {
        return NarrowFulfilmentMethodInputType.DELIVERY
      }
      if (
        fulfilmentFilterData.where.fulfilmentMethods.includes(
          NarrowFulfilmentMethodInputType.COLLECTION
        )
      ) {
        return NarrowFulfilmentMethodInputType.COLLECTION
      }
      if (
        fulfilmentFilterData.where.fulfilmentMethods.includes(
          NarrowFulfilmentMethodInputType.TABLE
        )
      ) {
        return NarrowFulfilmentMethodInputType.TABLE
      }
      return NarrowFulfilmentMethodInputType.DELIVERY
    },
    // map current when type to fulfilmentTimeType
    get timeType(): FulfilmentTimeType | undefined {
      if (fulfilmentFilterData.when?.type === FulfilmentFilterWhenType.ASAP) {
        return FulfilmentTimeType.ASAP
      }
      if (
        fulfilmentFilterData.when?.type === FulfilmentFilterWhenType.PREORDER
      ) {
        return FulfilmentTimeType.PREORDER
      }
    },
    // the active fulfilment methods, in the order they should be displayed
    sortedFulfilmentMethods: ALL_FULFILMENT_METHODS_SORTED.filter(method =>
      fulfilmentFilterData.where.fulfilmentMethods.includes(method)
    ),
    // the active fulfilment methods, defaulting to all if none are active
    nonEmptyFulfilmentMethods: fulfilmentFilterData.where.fulfilmentMethods
      .length
      ? fulfilmentFilterData.where.fulfilmentMethods
      : ALL_FULFILMENT_METHODS_SORTED,
  }
}

export type FulfilmentFilterData = ReturnType<typeof extendFulfilmentFilterData>

export const getBaseDataFromExtendedData = (
  extendedData: FulfilmentFilterData
): FulfilmentFilter => {
  return omit(extendedData, [
    'isExtended',
    'sortedFulfilmentMethods',
    'nonEmptyFulfilmentMethods',
  ])
}
