import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { OrderMode } from '@src/../__generated__/globalTypes'
import { Redirect } from '@src/components/Router/Redirect'
import { useMarketplace } from '@src/hooks/useMarketplace'

export const OutletRedirect: React.FC = () => {
  const location = useLocation()
  const { urlPath, orderMode } = useMarketplace()
  const { outletId, merchantName } = useParams()

  let path = '/'

  if (merchantName) {
    switch (orderMode) {
      case OrderMode.POSTCODE:
        path = `/${urlPath}/${outletId}/${merchantName}`
        break
      case OrderMode.LIST:
        path = `/${urlPath}/${outletId}/${merchantName}`
        break
      case OrderMode.SINGLE:
        path = `/${urlPath}/${outletId}/${merchantName}`
        break
      default:
        break
    }
  }

  // Old mobile routes are deeplinked into a menu section.
  // We don't have this yet but we will, so an old mobile route can be deep linked back to the existing menu section.
  // The route will look like this /takeaway/:outletId/the-hot-mango-caf-/menu/Hot-Sandwiches/:menuCategoryId
  // if we need more control here ie we want to change the route from the above structure then this will need its own redirect
  // so we can pattern match the url params for the category etc.
  if (location.pathname.includes('menu')) {
    const [, afterMenu] = location.pathname.split('/menu/')
    if (afterMenu) {
      path = `${path}/menu/${afterMenu}`
    }
  }

  if (location.pathname.includes('/info')) {
    path = `${path}/info`
  }

  return <Redirect to={path} />
}
