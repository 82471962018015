import React, { useEffect } from 'react'
import styled from 'styled-components'

import { LoaderCheck } from '@src/components/Check/LoaderCheck'
import {
  MainRouteName,
  RoutingParams,
  useAccountRouter,
} from '@src/hooks/useAccountRouter'

const SuccessText = styled.p`
  margin: 0;
  text-align: center;
  font-size: 18px;
`

export const SuccessScreen = ({
  successText,
  returnToRoute,
}: {
  successText: string
  returnToRoute: RoutingParams<MainRouteName> | null
}): JSX.Element => {
  const { setRoute } = useAccountRouter()

  useEffect(() => {
    let timeout: number
    if (returnToRoute) {
      timeout = window.setTimeout(() => {
        setRoute({
          mainRouteName: returnToRoute.mainRouteName,
          childRouteName: returnToRoute.childRouteName,
        })
      }, 2000)
    }

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [returnToRoute, setRoute])

  return (
    <>
      <LoaderCheck />
      <SuccessText>{successText}</SuccessText>
    </>
  )
}
