import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  SingleOutlet,
  SingleOutletDirection,
} from '@src/components/SingleOutlet/types'
import { NoneFoundSVG } from '@src/components/SVGS/NoneFoundSVG'
import { breakpoints } from '@src/constants/breakpoints'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { screenResolutionVar } from '@src/models/screenResolution'
import { DateifyOutlet } from '@src/utils/fulfilmentTimes/parsers'

import {
  NoneFound,
  OutletListContainer,
  StyledOutletCard,
} from './OutletList.styles'
import { DeliveryPreorderWindow } from '@src/graphql-types'

export const OutletCards: React.FC<{
  outlets: DateifyOutlet<
    Pick<
      SingleOutlet,
      | 'id'
      | 'displayName'
      | 'restaurant'
      | 'availableFulfilmentInputMethods'
      | 'description'
      | 'deliveryMinimumOrderValue'
      | 'collectionMinimumOrderValue'
      | 'isOnline'
      | 'isOpen'
      | 'distanceFromUserKM'
      | 'ASAPDeliveryDuration'
      | 'prepTime'
      | 'statusText'
      | 'outletLogoOverride'
      | 'nextOpenDate'
      | 'availableFulfillmentMethods'
      | 'fulfilmentRange'
      | 'outletAllDiscounts'
      | 'specialOfferFlags'
      | 'outletCuisines'
      | 'outletLoyaltyCards'
    > & {
      deliveryPreorderWindows?: DeliveryPreorderWindow[]
      collectionPreorderTimes?: string[]
    }
  >[]
}> = ({ outlets }) => {
  const screenResolution = useReactiveVar(screenResolutionVar)
  const marketplace = useMarketplace()

  const { t } = useTranslation(['copy'])

  return (
    <OutletListContainer>
      {outlets.length ? (
        outlets.map((outlet, index) => (
          <StyledOutletCard
            key={`outletCard` + outlet.id}
            outlet={outlet}
            direction={
              screenResolution.width < breakpoints.tablet
                ? SingleOutletDirection.ROW
                : SingleOutletDirection.COLUMN
            }
            dataTestId={`outlet-list-${index}`}
          />
        ))
      ) : (
        <NoneFound>
          <NoneFoundSVG id={'noneFound'} />
          <h1>{t('no_outlets_found', { urlPath: marketplace.urlPath })}</h1>
          <p>
            {t('no_all_outlets', {
              urlPath: marketplace.urlPath,
            })}
          </p>
        </NoneFound>
      )}
    </OutletListContainer>
  )
}
