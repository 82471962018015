import { css, Interpolation } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

type breakpointKeys = keyof typeof breakpoints

export const between = ({
  start,
  end,
  styles,
}: {
  start: breakpointKeys
  end: breakpointKeys
  styles: Interpolation<any>
}): Interpolation<any> => {
  const min = breakpoints[start]
  const max = breakpoints[end] - 1
  return css`
    @media (min-width: ${min}px) and (max-width: ${max}px) {
      ${styles}
    }
  `
}
