import { formatISO } from 'date-fns'

import {
  LocationType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { FulfilmentFilterWhenType } from '@src/hooks/useFulfilmentFilter/validation'
import { outletListVariables } from '@src/pages/OutletList/queries/__generated__/outletList'
import { ALL_FULFILMENT_METHODS_SORTED } from '@src/utils/fulfilment'
import { roundToNearestMinutes } from '@src/utils/roundToNearestMinutes'

export const getOutletSearchVariables = ({
  fulfilmentFilter,
  marketplaceId,
  cuisineIds,
  featured,
}: {
  fulfilmentFilter: ReturnType<typeof useFulfilmentFilter>['data']
  marketplaceId: string
  cuisineIds?: string[]
  featured?: boolean
}): outletListVariables => {
  // cuisines ignore all other filters
  if (cuisineIds?.length) {
    return {
      input: {
        locationType: LocationType.EVERYWHERE,
        marketplaceId,
        cuisineIds,
        narrowFulfilmentMethods: ALL_FULFILMENT_METHODS_SORTED,
        whenType: fulfilmentFilter.when.type,
      },
      fulfilmentMethod: fulfilmentFilter.priorityFulfilmentMethod,
      acceptedFulfilmentMethods: fulfilmentFilter.where.fulfilmentMethods,
      fulfilmentLocation: fulfilmentFilter.where.location,
    }
  }

  const fulfilmentMethods = fulfilmentFilter.where.fulfilmentMethods.length
    ? fulfilmentFilter.where.fulfilmentMethods
    : ALL_FULFILMENT_METHODS_SORTED

  // dont pass through the preorder date if the only method is table
  const preorderDate =
    fulfilmentMethods.includes(NarrowFulfilmentMethodInputType.TABLE) &&
    fulfilmentMethods.length === 1
      ? undefined
      : getPreorderDate(fulfilmentFilter.when)

  const baseInput = {
    marketplaceId,
    narrowFulfilmentMethods: fulfilmentMethods,
    selectedDate: preorderDate,
    featured,
  }

  switch (fulfilmentFilter.where.location.type) {
    case LocationType.EVERYWHERE:
      return {
        fulfilmentMethod: fulfilmentFilter.priorityFulfilmentMethod,
        input: {
          ...baseInput,
          locationType: LocationType.EVERYWHERE,
          whenType: fulfilmentFilter.when.type,
        },
        acceptedFulfilmentMethods: fulfilmentMethods,
        fulfilmentLocation: fulfilmentFilter.where.location,
        fulfilmentTimeType: fulfilmentFilter.timeType,
      }

    case LocationType.POSTCODE:
      return {
        fulfilmentMethod: fulfilmentFilter.priorityFulfilmentMethod,
        input: {
          ...baseInput,
          locationType: LocationType.POSTCODE,
          postAndCountryCode:
            fulfilmentFilter.where.location.postAndCountryCode,
          whenType: fulfilmentFilter.when.type,
        },
        acceptedFulfilmentMethods: fulfilmentMethods,
        fulfilmentLocation: fulfilmentFilter.where.location,
        fulfilmentTimeType: fulfilmentFilter.timeType,
      }

    case LocationType.ADDRESS:
      return {
        fulfilmentMethod: fulfilmentFilter.priorityFulfilmentMethod,
        input: {
          ...baseInput,
          locationType: LocationType.ADDRESS,
          addressId: fulfilmentFilter.where.location.addressId,
          whenType: fulfilmentFilter.when.type,
        },
        acceptedFulfilmentMethods: fulfilmentMethods,
        fulfilmentLocation: fulfilmentFilter.where.location,
        fulfilmentTimeType: fulfilmentFilter.timeType,
      }

    case LocationType.COORDINATES:
      return {
        fulfilmentMethod: fulfilmentFilter.priorityFulfilmentMethod,
        input: {
          ...baseInput,
          locationType: LocationType.COORDINATES,
          coordinates: {
            lng: fulfilmentFilter.where.location.coordinates.lng,
            lat: fulfilmentFilter.where.location.coordinates.lat,
          },
          whenType: fulfilmentFilter.when.type,
        },
        acceptedFulfilmentMethods: fulfilmentMethods,
        fulfilmentLocation: fulfilmentFilter.where.location,
        fulfilmentTimeType: fulfilmentFilter.timeType,
      }

    case LocationType.DELIVERY_ZONE:
      return {
        fulfilmentMethod: fulfilmentFilter.priorityFulfilmentMethod,
        input: {
          ...baseInput,
          locationType: LocationType.DELIVERY_ZONE,
          deliveryZoneId: fulfilmentFilter.where.location.zoneId,
          whenType: fulfilmentFilter.when.type,
        },
        acceptedFulfilmentMethods: fulfilmentMethods,
        fulfilmentTimeType: fulfilmentFilter.timeType,
      }
  }
}

const getPreorderDate = (
  when: ReturnType<typeof useFulfilmentFilter>['data']['when']
): string | undefined => {
  if (when.type === FulfilmentFilterWhenType.PREORDER) {
    return formatISO(when.preorderDate)
  }

  if (when.type === FulfilmentFilterWhenType.ASAP) {
    return formatISO(
      roundToNearestMinutes(new Date(), {
        nearestTo: 5,
        roundingMethod: 'floor',
      })
    )
  }
}
