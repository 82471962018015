export const singleOrder = {
  order_number: 'Order',
  order_placed: 'Order Placed',
  order_details: 'Order Details',
  searching_for_order: 'Searching for your order...',
  this_order_doesnt_exist: "This order doesn't exist",
  status: 'Status',
  original_subtotal: 'Subtotal',
  delivery_charge: 'Delivery Charge',
  table_charge: 'Table Charge',
  collection_charge: 'Collection Charge',
  discount: 'Discount',
  service_fee: 'Service Fee',
  total: 'Total',
  track_order: 'Track My Delivery',
  order_again: 'Order Again',
  new_order_confirm: 'Start a new order?',
  type_of_payment: 'Type of Payment',
  in_person_payment: 'In-Person Payment',
  card_number: 'Card Number',
  expiry_date: 'Expiry Date',
  amount_charged: 'Amount Charged',
  date_charged: 'Date Charged',
  placed: 'Placed',
  updated: 'Updated',
  pending: 'Pending',
  preparing: 'Preparing',
  preparing_order: 'Preparing your order...',
  delivery_address: 'Delivery Address',
  delivery_note: 'Delivery Note',
  collection_note: 'Collection Note',
  table_note: 'Table Note:',
  order_note: 'Order Note',
  billing_address: 'Billing Address',
  order_enquiries: 'Order Enquiries',
  order_complete: 'This order has been marked as complete.',
  cancelled: 'Cancelled',
  ready: 'Ready',
  order_marked_cancelled: '{{restaurantName}} marked your order as cancelled.',
  order_marked_rejected: '{{restaurantName}} marked your order as rejected.',
  order_on_way: 'Your order is on its way.',
  error_message: 'Sorry, there has been an error',
  rejected: 'Rejected',
  awaiting_order_acceptance:
    'Waiting for {{restaurantName}} to accept your order.',
  ready_for_collection: 'Your order is ready for collection. Enjoy!',
  rejection_notes: 'Rejection Notes:',
  cancellation_notes: 'Cancellation Notes:',
  none: 'None',
  header: 'Header',
  detail: 'Detail',
  confirm: 'Confirm',
  cancel: 'Cancel',
  cash: 'Cash',
  vat: 'VAT',
  today: 'Today',
  tomorrow: 'Tomorrow',
  stamps_earned: 'Stamps Earned',
}
