export const outletOverview = {
  nearby: 'Nearby',
  currently_closed: 'Currently Closed',
  more_info: 'More Info',
  back_to: 'Back to {{marketplace}}',
  min_order: 'Min Order {{val}}',
  no_min_order: 'No Min Order',
  no_method_selected: 'No order method selected',
  logo_label: '{{restaurant}} logo',
  no_fulfilment_chosen: 'No fulfilment chosen',
  tomorrow: 'Tomorrow',
  today: 'Today',
  collection: 'Collection',
  delivery: 'Delivery',
  table: 'Table Service',
  back_to_outlets: 'Back to {{urlPath}} near {{ searchLocation }}',
  all: 'All',
  back_to_deliveryzone: 'Back to {{deliveryZoneName}}',
  back_to_outlet_page: 'Back to {{outletName}}',
  back_to_search_results: 'Back to Search Results',
  arriving_by: 'Arriving by',
  ready_by: 'Ready by',
  enter_location: 'Enter Location',
  deals_and_offers: 'Deals & Offers',
}
