import { useNavigate } from 'react-router-dom'

type NavigateToMainContent = (options?: { pathname?: string }) => void

export const useSecondaryNavigation = (): {
  navigateToMainContent: NavigateToMainContent
} => {
  const navigate = useNavigate()

  return {
    navigateToMainContent: options => {
      navigate(options?.pathname ?? '/')
    },
  }
}
