import { useLazyQuery } from '@apollo/client'
import { FormikConfig } from 'formik'
import { pick } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CountryCodeType, DeliveryCostType } from '@src/graphql-types'
import {
  useOutletFulfilment,
  OutletFulfilmentStateType,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { useCreateAddressMutation } from '@src/hooks/sharedQueries/useCreateAddressMutation/useCreateAddressMutation'
import { CustomerDetailsAndAddressesDocument } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/queries/__generated__/CustomerDetailsAndAddressesQuery.graphql-interface'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { mapZodToFormikErrors } from '@src/utils/mapZodToFormikErrors'

import { addressCreationZodTransform } from './addressCreationZodTransform'
import {
  AddressType,
  DeliveryFulfilmentFormSchema,
  EMPTY_ADDRESS,
  ExistingAddressSchema,
  createDeliveryFulfilmentFormSchema,
  existingAddressSchema,
} from './schema'

import { DeliveryEstimateForAddressDocument } from '../mutations/__generated__/DeliveryEstimateForAddress.graphql-interface'

// hook which returns props for a Formik form for delivery fulfilment
// it uses the current selected fulfilment, outlet and menu items to calculate:
// * the initial values for the form
// * the handle submit function, which:
//   1. performs validation from a calculated zod schema
//   2. creates the new address if necessary
//   3. asserts the address can be delivered to
//   4. updates the global state with the new fulfilment data
export const useDeliveryFulfilmentFormikProps = () => {
  const {
    data: { currentFulfilment, historicalData },
    setCurrentFulfilment,
    outlet,
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const marketplace = useMarketplace()
  const { t } = useTranslation('checkout')
  const checkoutRouter = useCheckoutRouter()
  // used to assert if delivery is available for the address
  const [deliveryEstimateForAddress] = useLazyQuery(
    DeliveryEstimateForAddressDocument
  )

  const [createAddress] = useCreateAddressMutation({
    refetchQueries: [
      {
        query: CustomerDetailsAndAddressesDocument,
        variables: {
          outletId: outlet.id,
        },
      },
    ],
  })

  const zodValidationSchema = createDeliveryFulfilmentFormSchema(t)

  // define initial values based on current fulfilment
  const initialValues: DeliveryFulfilmentFormSchema = useMemo(() => {
    const baseInitialValues = {
      ageVerificationConfirmed:
        historicalData.ageVerificationConfirmed || false,
      deliveryNotes: historicalData.deliveryNotes || '',
      addressId: historicalData.addressId || '',
    }
    return currentFulfilment.type ===
      CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS
      ? {
          addressType: AddressType.EXISTING,
          ...baseInitialValues,
        }
      : {
          addressType: AddressType.NEW,
          addAddress: {
            ...EMPTY_ADDRESS,
            postcode:
              currentFulfilment.type === CurrentFulfilmentType.DELIVERY_POSTCODE
                ? currentFulfilment.postAndCountryCode.postcode
                : '',
            countryCode:
              currentFulfilment.type === CurrentFulfilmentType.DELIVERY_POSTCODE
                ? currentFulfilment.postAndCountryCode.countryCode
                : (marketplace.country.ISO3166Alpha2 as CountryCodeType),
          },
          ...baseInitialValues,
        }
  }, [
    currentFulfilment,
    historicalData.ageVerificationConfirmed,
    historicalData.deliveryNotes,
    marketplace.country.ISO3166Alpha2,
  ])

  const handleSubmit: FormikConfig<DeliveryFulfilmentFormSchema>['onSubmit'] =
    useCallback(
      async (values, { setErrors, setValues }) => {
        // create the address and cast errors to zod errors
        const addressCreationParseResult = await addressCreationZodTransform({
          validatedData: values,
          createAddressMutation: createAddress,
          t,
        })

        // if address creation failed, set errors and return without trying to get a delivery estimate
        if (!addressCreationParseResult.success) {
          setErrors(mapZodToFormikErrors(addressCreationParseResult.error))
          return
        }

        const { addressId } = addressCreationParseResult.data

        // assert delivery is available for the address
        // (addresses will be disabled in the address list if delivery is unavailable)
        // but may have been previously selected
        const { data: deliveryEstimateQueryData } =
          await deliveryEstimateForAddress({
            variables: {
              addressId: addressId,
              outletId: outlet.id,
              preorderFor: historicalData.deliveryPreorderWindow
                ? historicalData.deliveryPreorderWindow.start.toISOString()
                : undefined,
            },
          })
        if (!deliveryEstimateQueryData?.deliveryEstimateForAddress.id) {
          // delivery unavailable - no need to set errors as the address
          // existing addresses list automatically shows a "delivery unavailable" message
          // if new address was created update formik state to newly created (now-"existing") address
          if (values.addressType === AddressType.NEW) {
            const updatedData = {
              ...pick(values, Object.keys(existingAddressSchema.shape)),
              addressType: AddressType.EXISTING,
              addressId: addressCreationParseResult.data.addressId,
            } as ExistingAddressSchema
            setValues(updatedData)
          }
          setErrors({ addressId: t('delivery_unavailable') })
          return
        }
        if (
          deliveryEstimateQueryData.deliveryEstimateForAddress.type ===
          DeliveryCostType.NETWORK_FALLBACK
        ) {
          // TODO: Error handling
          return
        }

        setCurrentFulfilment({
          type: CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS,
          addressId: addressCreationParseResult.data.addressId,
          deliveryPreorderWindow: historicalData.deliveryPreorderWindow,
          deliveryNotes: values.deliveryNotes,
          ageVerificationConfirmed: values.ageVerificationConfirmed,
          fulfilmentId: deliveryEstimateQueryData.deliveryEstimateForAddress.id,
        })

        void checkoutRouter.override(
          outlet.restaurant.allowAddOnItems
            ? CheckoutRoute.OFFERS
            : CheckoutRoute.PAYMENT
        )
      },
      [
        checkoutRouter,
        createAddress,
        deliveryEstimateForAddress,
        historicalData.deliveryPreorderWindow,
        outlet.id,
        outlet.restaurant.allowAddOnItems,
        setCurrentFulfilment,
        t,
      ]
    )

  return {
    zodValidationSchema,
    initialValues,
    handleSubmit,
  } as const
}
