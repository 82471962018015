import { useLazyQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ButtonType } from '@src/components/Button'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { useMarketplaceDeliveryZonesQuery } from '@src/hooks/sharedQueries/useMarketplaceDeliveryZones/useMarketplaceDeliveryZones'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { DeliveryZoneInput } from '@src/pages/Home/LocationInput/DeliveryZoneInput'

import {
  Title,
  Underlay,
  Container,
  StyledChoiceButton,
  CenteredText,
} from '../MenuItemModalAlertStyles'
import { zoneDeliveryEstimateDocument } from '../queries/__generated__/zoneDeliveryEstimate.graphql-interface'

export const DeliveryZonePickerModal: React.FC<{
  onSelected: () => void
}> = ({ onSelected }) => {
  const { t } = useTranslation('navigation')
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { urlPath: merchantURLPath, merchantType } = useMarketplace()
  const navigate = useNavigate()
  const [checkZoneIsAvailableForDelivery] = useLazyQuery(
    zoneDeliveryEstimateDocument
  )
  // this controls a modal which is show if the user has selected a delivery zone
  // but the zone is not available for this outlet
  const [showDeliveryUnavailableModal, setShowDeliveryUnavailableModal] =
    useState(false)

  const handleFormSubmit = async (zone: { id: string }) => {
    const deliveryZoneData = await checkZoneIsAvailableForDelivery({
      variables: {
        outletId: outletFulfilment.outlet.id,
        deliveryZoneId: zone.id,
      },
    })

    // delivery zone is automatically set
    if (deliveryZoneData.data?.deliveryEstimateForZone.id) {
      outletFulfilment.setCurrentFulfilment({
        type: CurrentFulfilmentType.DELIVERY_ZONE,
        zoneId: zone.id,
        deliveryPreorderWindow:
          outletFulfilment.data.historicalData.deliveryPreorderWindow,
      })
    }
    if (!deliveryZoneData.data?.deliveryEstimateForZone.id) {
      setShowDeliveryUnavailableModal(true)
    }
  }

  const { data, loading } = useMarketplaceDeliveryZonesQuery()

  if (loading) {
    return null
  }

  const selectedDeliveryZoneName =
    (outletFulfilment.data.historicalData.zoneId &&
      data?.deliveryZonesByMarketplaceId.find(
        zone => zone.id === outletFulfilment.data.historicalData.zoneId
      )?.name) ||
    'all'

  const outletListNavLink = `/${selectedDeliveryZoneName}/${merchantURLPath}`

  return (
    <>
      <Underlay />
      <Container>
        {showDeliveryUnavailableModal ? (
          <>
            <Title>
              {t('delivery_not_available', {
                locationType: t('delivery_zone_option_title').toLowerCase(),
              })}
            </Title>
            <br />
            <StyledChoiceButton
              buttonType={ButtonType.SECONDARY}
              content={t('new_delivery_zone')}
              onClick={() => setShowDeliveryUnavailableModal(false)}
            />
            {outletFulfilment.outlet.prioritisedFulfilmentMethods.includes(
              NarrowFulfilmentMethodInputType.COLLECTION
            ) && (
              <StyledChoiceButton
                buttonType={ButtonType.SECONDARY}
                content={t('order_for_collection')}
                onClick={() => {
                  outletFulfilment.setCurrentFulfilment({
                    type: CurrentFulfilmentType.COLLECTION,
                    collectionPreorderDatetime:
                      outletFulfilment.data.historicalData
                        .collectionPreorderDatetime,
                  })
                  outletFulfilment.save()
                  onSelected()
                }}
              />
            )}
            <CenteredText>{t('or')}</CenteredText>
            <StyledChoiceButton
              buttonType={ButtonType.SECONDARY}
              content={t('go_back_and_find_outlets', {
                merchantType:
                  merchantType === 'RESTAURANT'
                    ? t('restaurants')
                    : t('businesses'),
              })}
              onClick={() => {
                navigate(outletListNavLink)
              }}
            />
          </>
        ) : (
          <>
            <Title>{t('unknown_location')}</Title>
            <br />
            <DeliveryZoneInput
              onSubmit={handleFormSubmit}
              selectedDeliveryZoneId={
                outletFulfilment.data.historicalData.zoneId
              }
            />
          </>
        )}
      </Container>
    </>
  )
}
