import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ErrorContainer } from '@src/components/Errors/error.styles'

import { FourZeroFourMessage } from './fourzerofour.styles'

export const FourZeroFour: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <ErrorContainer>
      <FourZeroFourMessage>{t('four_zero_four')}</FourZeroFourMessage>
      <button onClick={() => navigate('/')}>{t('back_to_homepage')}</button>
    </ErrorContainer>
  )
}
