import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { CurrentOutletProvider } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/CurrentOutletProvider'
import { BusinessHome } from '@src/pages/BusinessHome'
import { FourZeroFour } from '@src/pages/FourZeroFour/FourZeroFour'
import { OutletPageStateWrapper } from '@src/pages/OutletPage/OutletPageStateWrapper'

export const VendorRoutes: React.FC = () => {
  const location = useLocation()

  return (
    <Routes location={location}>
      {/* Routes in here other than '/' are handled by the burger menu which 
      is designed to open over the homepage if you land on any of these routes. */}
      <Route path="/" element={<BusinessHome />} />

      <Route
        path="/:fulfilmentMethod(all|delivery|collection)"
        element={<BusinessHome />}
      />

      <Route path="/accept-discount" element={<BusinessHome />} />

      <Route path="/register-and-accept-discount" element={<BusinessHome />} />

      <Route path="/404" element={<FourZeroFour />} />

      <Route
        path={`/:outletId/:merchantName`}
        element={
          <CurrentOutletProvider>
            <OutletPageStateWrapper />
          </CurrentOutletProvider>
        }
      />

      <Route path="/*" element={<Navigate to="/404" />} />
    </Routes>
  )
}
