export const customerLoyaltyCard = {
  you_have_collected: `You've collected`,
  you_are_earning: `You're earning`,
  so_far: 'so far!',
  start_collecting_today: 'Start collecting today!',
  ready_to_collect: `Ready to redeem your`,
  reward: `reward!`,
  my_loyalty_cards: 'My Loyalty Cards',
  earn_stamps_at: 'Earn stamps at',
  earn_stamps_at_all: 'All Vendors',
  valid_dates: 'Valid dates and days',
  minimum_total_spend: 'Minimum total spend',
  minimum_subtotal_spend: 'Minimum subtotal spend',
  stamp_value: 'Stamp value',
  orders: 'Orders',
  terms_and_conditions: 'Terms & conditions',
  error_fetching: 'Error fetching this loyalty card',
  spend: 'Spend',
  more_to_earn_a_stamp: 'more to earn a stamp.',
  earn: 'Earn',
  on_next_stamp: 'on your next stamp!',
  on_first_stamp: 'on your first stamp!',
  will_be_discounted:
    'will be automatically discounted against your next order. ',
  how_many_stamps_away: `You're only {{numberOfStamps}}away from using your discount. Order again for your next stamp. `,
  start_collecting_today_extra_info: `Start collecting today! Order from qualifying {{businessOrRestaurant}} to earn your first stamp.`,
  amount_saved: `{{amountSaved}}`,
  potential_to_earn: `{{potentialToEarn}}`,
  loyalty_cards_available: `{{numOfCards}} Loyalty Cards`,
  num_of_cards: '{{numOfCards}} Cards',
  cash_orders_not_accepted: 'This card does not accept cash orders.',
  unavailable_today: 'Unavailable today',
  card_unavailable_today: 'Sorry, this card is not available today.',
}
