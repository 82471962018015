import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type OutletAvailabilityQueryVariables = Types.Exact<{
  outletId: Types.Scalars['String']
  fulfilmentMethod: Types.NarrowFulfilmentMethodInputType
}>

export type OutletAvailabilityQuery = {
  outlet: {
    __typename: 'Outlet'
    id: string
    closedUntil: string | null
    nextOpenDate: string
    isOpen: boolean
    isOnline: boolean
    isOrderable: boolean
    prepTime: number
    collectionPreorderTimes: Array<string>
    openingTimesArray: Array<{
      __typename: 'OpeningTime'
      id: string
      timeSlot: number
      start: { __typename: 'DayAndTime'; id: string; day: number; time: string }
      end: { __typename: 'DayAndTime'; id: string; day: number; time: string }
    }>
    availableFulfilments: Array<{
      __typename: 'AvailableFulfilment'
      fulfilmentMethod: Types.NarrowFulfilmentMethodInputType
      fulfilmentTimeTypes: Array<Types.FulfilmentTimeType>
    }>
    deliveryPreorderWindows: Array<{
      __typename: 'DeliveryPreorderWindow'
      start: string
      end: string
      isFull: boolean
    }>
    statusText: {
      __typename: 'OutletStatusText'
      orderButtonTranslation: string
      openingTimeTranslation: {
        __typename: 'OpeningTimeTranslation'
        key: string
        datetime: string | null
        datetimeFormat: string | null
        days: string | null
      }
    }
  } | null
}

export const OutletAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OutletAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fulfilmentMethod' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NarrowFulfilmentMethodInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closedUntil' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimesArray' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'day' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'day' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeSlot' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextOpenDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFulfilments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfilmentMethod' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfilmentTimeTypes' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOrderable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepTime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collectionPreorderTimes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryPreorderWindows' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'returnFullWindows' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isFull' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statusText' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fulfilmentMethod' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fulfilmentMethod' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTimeTranslation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'datetime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'datetimeFormat' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'days' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderButtonTranslation' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OutletAvailabilityQuery,
  OutletAvailabilityQueryVariables
>
