import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type CheckoutBasketTotalsQueryVariables = Types.Exact<{
  basketData: Types.CheckoutBasketTotalsInput
}>

export type CheckoutBasketTotalsQuery = {
  checkoutBasketTotals: {
    __typename: 'CheckoutTotalsV2'
    fulfilmentId: string
    nonAddOnBasketItemsTotal: number
    addOnBasketItemsTotal: number
    fulfilmentCharge: number
    serviceCharge: number
    sumTotal: number
    expiryAt: string
    isFixedFulfilment: boolean
    appliedDiscount: {
      __typename: 'AppliedDiscount'
      reductionAmount: number
      discount: {
        __typename: 'Discount'
        id: string
        name: string
        voucher: string | null
        discountAmount: number | null
        discountPercentage: number | null
        loyaltyCard: {
          __typename: 'LoyaltyCard'
          id: string
          loyaltyCardIcon: string
          requiredStamps: number
          allowCashOrders: boolean
          netTotal: number | null
        }
      }
    } | null
    loyaltyCards: Array<{
      __typename: 'LoyaltyCard'
      id: string
      active: boolean
      loyaltyCardIcon: string
      loyaltyCardColor: string
      termsAndConditions: string
      discountStrategy: Types.LoyaltyCardDiscountStrategy
      allowCashOrders: boolean
      netTotal: number | null
      canStampBeEarnedToday: boolean | null
      requiredStamps: number
      loyaltyCardStamps: Array<{
        __typename: 'LoyaltyCardStamp'
        id: string
        stampClaimedOnOrder: { __typename: 'Order'; id: string } | null
        order: { __typename: 'Order'; id: string; netTotal: number }
      }>
      discount: {
        __typename: 'Discount'
        id: string
        name: string
        discountAmount: number | null
        discountPercentage: number | null
        daysOfWeek: Array<number> | null
        customerEnrolled: boolean
        customerEmail: string | null
        endDate: string | null
        startDate: string | null
        minimumSubtotalGross: number | null
      }
    }>
  }
}

export const CheckoutBasketTotalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckoutBasketTotals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'basketData' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CheckoutBasketTotalsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkoutBasketTotals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'basketData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'basketData' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfilmentId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nonAddOnBasketItemsTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addOnBasketItemsTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfilmentCharge' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serviceCharge' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appliedDiscount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'voucher' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discountAmount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'discountPercentage',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loyaltyCard' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'loyaltyCardIcon',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'requiredStamps',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'allowCashOrders',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'netTotal' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reductionAmount' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sumTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isFixedFulfilment' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loyaltyCards' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardIcon' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termsAndConditions' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountStrategy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowCashOrders' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'netTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canStampBeEarnedToday' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requiredStamps' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardStamps' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'stampClaimedOnOrder',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'netTotal' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discountAmount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'discountPercentage',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'daysOfWeek' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customerEnrolled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customerEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'minimumSubtotalGross',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutBasketTotalsQuery,
  CheckoutBasketTotalsQueryVariables
>
