import { getBaseDataFromExtendedData } from '../extendData/extendFulfilmentFilterData'
import { CurriedFulfilmentFilterHookMethodArgs } from '../types'
import { FulfilmentFilter } from '../validation'

export const setWhen =
  ({
    currentExtendedData,
    updateFulfilmentFilter,
  }: CurriedFulfilmentFilterHookMethodArgs) =>
  (updateData: FulfilmentFilter['when']): void => {
    const baseData = getBaseDataFromExtendedData(currentExtendedData)
    // update isActive values for each fulfilment method

    updateFulfilmentFilter({
      ...baseData,
      when: updateData,
    })
  }
