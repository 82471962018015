import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import {
  Route,
  useLocation,
  Routes,
  Navigate,
  useParams,
  Params,
} from 'react-router-dom'

import { CurrentOutletProvider } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/CurrentOutletProvider'
import {
  MainRouteName,
  useAccountRouter,
  HelpChildName,
  LoginChildName,
  getQueryStringFromRoute,
} from '@src/hooks/useAccountRouter'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { jwtVar } from '@src/models/customer/jwt'
import { FourZeroFour } from '@src/pages/FourZeroFour/FourZeroFour'
import { Home } from '@src/pages/Home'
import { OutletPageStateWrapper } from '@src/pages/OutletPage/OutletPageStateWrapper'

import { outletListRoutes } from './OutletListRoutes'
import { MerchantCategoryRedirect } from './redirects/MerchantCategoryRedirect'
import { OutletRedirect } from './redirects/OutletRedirect'

export const MarketplaceRoutes: React.FC = () => {
  const marketplace = useMarketplace()
  const location = useLocation()
  const params = useParams()

  const { setRoute } = useAccountRouter()
  const {
    savedData: {
      where: { historicalData },
    },
  } = useFulfilmentFilter()

  const isAuthenticated = useReactiveVar(jwtVar) !== null

  const someLocationPresentInState = !!(
    historicalData.addressId ||
    historicalData.coordinates ||
    historicalData.postAndCountryCode ||
    historicalData.zoneId
  )

  useEffect(() => {
    if (location.pathname === '/password-reset') {
      const searchParams = new URLSearchParams(location.search)
      const cid = searchParams.get('cid') ?? undefined
      const token = searchParams.get('token') ?? undefined

      setRoute({
        mainRouteName: MainRouteName.LOGIN,
        childRouteName: LoginChildName.PASSWORD_RESET,
        cid,
        token,
      })
    }

    if (location.pathname === '/onboarding') {
      setRoute({ mainRouteName: MainRouteName.ADD_BUSINESS })
    }

    if (location.pathname === '/help') {
      setRoute({
        mainRouteName: MainRouteName.HELP,
        childRouteName: HelpChildName.FAQS,
      })
    }
  }, [location.pathname])

  return (
    <Routes location={location}>
      <Route
        path="/account/details"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.DETAILS,
            })}
          />
        }
      />
      <Route
        path="/account/addresses"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.ADDRESSES,
            })}
          />
        }
      />
      <Route
        path="/account/marketing"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.MARKETING,
            })}
          />
        }
      />
      <Route
        path="/account/orders/:orderId"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.ORDERS,
              pageDataId: params.orderId,
            })}
          />
        }
      />
      <Route
        path="/account/orders"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.ORDERS,
            })}
          />
        }
      />
      <Route
        path="/terms"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.TERMS,
            })}
          />
        }
      />
      <Route
        path="/privacy"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.PRIVACY,
            })}
          />
        }
      />
      <Route
        path="/cookies"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.COOKIES,
            })}
          />
        }
      />
      <Route
        path="/misc"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.MISC,
            })}
          />
        }
      />
      <Route
        path="/allergy"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.ALLERGY,
            })}
          />
        }
      />
      <Route
        path="/help/faqs"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.HELP,
              childRouteName: HelpChildName.FAQS,
            })}
          />
        }
      />
      <Route
        path="/add-business"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.ADD_BUSINESS,
            })}
          />
        }
      />
      <Route
        path="/onboarding"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.ADD_BUSINESS,
            })}
          />
        }
      />
      <Route
        path="/help"
        element={
          <Navigate
            to={getQueryStringFromRoute({
              mainRouteName: MainRouteName.HELP,
            })}
          />
        }
      />
      {marketplace.urlPath !== 'takeaways' && (
        <Route
          path="/takeaways"
          element={<Navigate to={`/${marketplace.urlPath}`} />}
        />
      )}
      {/* Routes in here other than '/' are handled by the burger menu which 
      is designed to open over the homepage if you land on any of these routes. */}
      {/* redirect '/' if we have enabled redirect from landing page */}
      {(marketplace.enableRedirectFromLandingPage ||
        (isAuthenticated && someLocationPresentInState)) && (
        <Route
          path={'/'}
          element={<Navigate to={`/${marketplace.urlPath}`} />}
        />
      )}
      <Route
        path="/"
        element={
          marketplace.enableRedirectFromLandingPage ||
          (isAuthenticated && someLocationPresentInState) ? (
            <Navigate to={`/${marketplace.urlPath}`} />
          ) : (
            <Home />
          )
        }
      />
      <Route
        path="/accept-discount"
        element={
          marketplace.enableRedirectFromLandingPage ||
          (isAuthenticated && someLocationPresentInState) ? (
            <Navigate to={`/${marketplace.urlPath}`} />
          ) : (
            <Home />
          )
        }
      />
      <Route
        path="/register-and-accept-discount"
        element={
          marketplace.enableRedirectFromLandingPage ||
          (isAuthenticated && someLocationPresentInState) ? (
            <Navigate to={`/${marketplace.urlPath}`} />
          ) : (
            <Home />
          )
        }
      />
      <Route path="/404" element={<FourZeroFour />} />
      <Route
        path={`/${marketplace.urlPath}/:outletId/:merchantName/*`}
        element={
          <CurrentOutletProvider>
            <OutletPageStateWrapper />
          </CurrentOutletProvider>
        }
      />
      {outletListRoutes({
        merchantURLPath: marketplace.urlPath,
        orderMode: marketplace.orderMode,
        deliveryZone: params.deliveryZone,
      })}

      {/* Redirection route */}
      {/* <Route
        path={`/:deliveryZone/:merchantCategory(${marketplace.urlPath})`}
        element={
          <Navigate
            to={`/${useParams().deliveryZone}/${useParams().merchantCategory}`}
            replace
          />
        }
      /> */}

      <Route
        path="/:deliveryZone-:merchantCategory"
        element={<MerchantCategoryRedirect />}
      />
      {/* Redirect old outlet routes */}
      <Route
        path="/:merchantName/:outletId/menu"
        element={<OutletRedirect />}
      />
      <Route
        path="/:merchantName/:outletId/menu/*"
        element={<OutletRedirect />}
      />
      <Route
        path="/:merchantName/:outletId/info"
        element={<OutletRedirect />}
      />
      <Route
        path="/:deliveryZone-:merchantCategory/:merchantName/:outletId/menu"
        element={<OutletRedirect />}
      />
      <Route
        path="/:deliveryZone-:merchantCategory/:merchantName/:outletId/menu/*"
        element={<OutletRedirect />}
      />
      <Route
        path="/:deliveryZone-:merchantCategory/:merchantName/:outletId/info"
        element={<OutletRedirect />}
      />
      {/* Redirect old checkout routes */}
      <Route
        path="/:outletId/:merchantName-checkout"
        element={
          isCheckoutRouteParams(params) ? <OutletRedirect /> : <FourZeroFour />
        }
      />
      <Route
        path="/:deliveryZone-:merchantCategory/:outletId/:merchantName-checkout"
        element={
          isCheckoutRouteParams(params) ? <OutletRedirect /> : <FourZeroFour />
        }
      />
      {/* Redirect misc routes */}
      <Route path={'/help'} element={<Navigate to="/" />} />
      <Route path={'/onboarding'} element={<Navigate to="/" />} />
      <Route path={'/password-reset'} element={<Navigate to="/" />} />
      <Route path="/*" element={<Navigate to="/404" />} />
    </Routes>
  )
}

// the path matching in react router works correctly but the inferred type is incorrect
// this ensures the correct type without a risky `as unknown`
function isCheckoutRouteParams(params: Readonly<Params<string>>): params is {
  outletId: string
  merchantName: string
} {
  return 'outletId' in params && 'merchantName' in params
}
