import { add, isBefore, max } from 'date-fns'

import { Outlet } from '@src/graphql-types'
import {
  FulfilmentFilterWhenASAP,
  FulfilmentFilterWhenAnytime,
  FulfilmentFilterWhenPreorder,
  FulfilmentFilterWhenType,
} from '@src/hooks/useFulfilmentFilter/validation'
import { DateifyOutlet } from '@src/utils/fulfilmentTimes/parsers'
import { ParsedDeliveryPreorderWindow } from '@src/utils/fulfilmentTimes/types'
import { getAvailableFulfilmentsByFulfilmentMethod } from '@src/utils/getAvailableFulfilmentsByFulfilmentMethod'

// calculates the delivery preorder window from the current fulfilment filter
// (attempts to find the first deliverable window today or on the date selected by the customer)
// returns null if it should be asap or there are no deliverable windows
export const calculateDeliveryPreorderWindow = ({
  outlet,
  fulfilmentFilterWhen,
}: {
  outlet: Pick<
    DateifyOutlet<Outlet>,
    | 'isOpen'
    | 'nextOpenDate'
    | 'prepTime'
    | 'deliveryPreorderWindows'
    | 'availableFulfilments'
  >
  fulfilmentFilterWhen:
    | Zod.infer<typeof FulfilmentFilterWhenAnytime>
    | Zod.infer<typeof FulfilmentFilterWhenASAP>
    | Zod.infer<typeof FulfilmentFilterWhenPreorder>
}): ParsedDeliveryPreorderWindow | null => {
  const availableFulfilmentsByFulfilmentMethod =
    getAvailableFulfilmentsByFulfilmentMethod(outlet.availableFulfilments)

  if (
    // customer selected preorder and outlet offers preorders
    (fulfilmentFilterWhen.type === FulfilmentFilterWhenType.PREORDER &&
      availableFulfilmentsByFulfilmentMethod.DELIVERY.PREORDER) ||
    // customer selected ANYTIME or ASAP but asap is not available
    ([FulfilmentFilterWhenType.ANYTIME, FulfilmentFilterWhenType.ASAP].includes(
      fulfilmentFilterWhen.type
    ) &&
      (!availableFulfilmentsByFulfilmentMethod.DELIVERY.ASAP || !outlet.isOpen))
  ) {
    // calculate best preorder window based on customer's selected preorder date / now + prep
    const earliestPreorderTime = add(
      outlet.isOpen ? new Date() : outlet.nextOpenDate,
      {
        minutes: outlet.prepTime,
      }
    )
    const earliestPreorderTimeOrSelectedPreorderTime =
      fulfilmentFilterWhen.type === FulfilmentFilterWhenType.PREORDER
        ? max([fulfilmentFilterWhen.preorderDate, earliestPreorderTime])
        : earliestPreorderTime

    // find the earliest window that has a `start` after the earliest preorder time
    return outlet.deliveryPreorderWindows.reduce<ParsedDeliveryPreorderWindow | null>(
      (acc, deliveryWindow) => {
        if (
          !deliveryWindow.isFull &&
          !isBefore(
            new Date(deliveryWindow.start),
            earliestPreorderTimeOrSelectedPreorderTime
          ) &&
          (!acc ||
            isBefore(new Date(deliveryWindow.start), new Date(acc.start)))
        ) {
          return {
            ...deliveryWindow,
            start: new Date(deliveryWindow.start),
            end: new Date(deliveryWindow.end),
          }
        }
        return acc
      },
      null
    )
  }

  // default to asap
  return null
}
