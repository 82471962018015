import React from 'react'
import styled, { css } from 'styled-components'

import { ArrowLeftSVG } from '../SVGS/ArrowLeftSVG'
import { CaretDown } from '../SVGS/CaretDown'
import { CaretLeft } from '../SVGS/CaretLeft'
import { CaretRight } from '../SVGS/CaretRight'
import { CaretUp } from '../SVGS/CaretUp'
import { CrossSVG } from '../SVGS/CrossSVG'
import { MinusSVG } from '../SVGS/MinusSVG'
import { PlusSVG } from '../SVGS/PlusSVG'

type Color = {
  default: string
  hover: string
}

const Container = styled.button<{
  size: number
  disabled?: boolean
  backgroundColor?: Color
  stroke?: Color
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  color: ${({ theme }) => theme.colors.brand};
  background-color: ${({ theme }) => theme.colors.grey};
  border: none;
  border-radius: 40px;
  padding: 2px 0 0 0;
  cursor: pointer;
  transition: color, background-color, 0.3s;

  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.colors.brand};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      pointer-events: none;
      color: #00000;
      cursor: default;
    `}
`
type IconKey =
  | 'plus'
  | 'minus'
  | 'cross'
  | 'caretUp'
  | 'caretDown'
  | 'arrowBack'
  | 'caretLeft'
  | 'caretRight'

export const IconButton: React.VFC<{
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  icon: string
  size?: number
  alt?: string
  backgroundColor?: Color
  stroke?: Color
  className?: string
  role?: string
}> = ({
  onClick,
  disabled = false,
  icon = 'plus',
  size = 32,
  alt,
  backgroundColor,
  stroke,
  className,
  role,
}) => {
  const iconMap = {
    plus: <PlusSVG id="plus" title={alt} />,
    minus: <MinusSVG id="minus" title={alt} />,
    cross: <CrossSVG id="close" title={alt} />,
    caretUp: <CaretUp id="expand" title={alt} />,
    caretDown: <CaretDown id="collapse" title={alt} />,
    arrowBack: <ArrowLeftSVG id="back" title={alt} />,
    caretLeft: <CaretLeft id="left" title={alt} />,
    caretRight: <CaretRight id="right" title={alt} />,
  }

  const iconKey: IconKey = icon as IconKey
  return (
    <Container
      className={className}
      disabled={disabled}
      onClick={onClick}
      size={size}
      backgroundColor={backgroundColor}
      stroke={stroke}
      role={role}
    >
      {iconMap[iconKey]}
    </Container>
  )
}
