import React from 'react'
import styled from 'styled-components'

import { Link, LinkProps } from '@src/components/Router/Link'
import { breakpoints } from '@src/constants/breakpoints'

import { NewTabLink } from '../NewTabLink'
import { AppStoreSVG } from '../SVGS/AppStoreSVG'
import { FacebookSVG } from '../SVGS/Facebook'
import { InstagramSVG } from '../SVGS/Instagram'
import { PlayStoreSVG } from '../SVGS/PlayStoreSVG'
import { TikTokSVG } from '../SVGS/TikTok'
import { TwitterXSVG } from '../SVGS/TwitterX'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.mainText};
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 24px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: 0px;
    padding: 32px;
    flex-direction: row;
    justify-content: flex-start;
  }
`

export const ContactContainer = styled.div`
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  @media (min-width: ${breakpoints.tablet}px) {
    border: none;
    width: 288px;
    margin-right: 16px;
  }
`

export const Title = styled.h4`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.brand};
`

export const Address = styled.address`
  font-size: 14px;
  font-style: normal;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.mainText};
`

// TODO we can merge these i think using 'as' prop
export const ContactEmail = styled.a`
  all: unset;
  cursor: pointer;
  font-weight: 700;
  padding: 16px 0px;
  color: ${({ theme }) => theme.colors.mainText};

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const ContactText = styled.p`
  all: unset;
  font-size: 14px;
  margin-bottom: 16px;
  display: block;
`

export const StyledLink = styled(Link)`
  all: unset;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.mainText};

  &:hover {
    text-decoration: underline;
    opacity: 1;
    color: ${({ theme }) => theme.colors.brand};
  }
`

const StyledLinkWithIcon = styled(Link)`
  all: unset;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.mainText};

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const StyledIconWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.mainText};
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const StyledLinkWrapper = styled.div`
  all: unset;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;

  color: ${({ theme }) => theme.colors.mainText};

  ${StyledIconWrapper} {
    &:path {
      color: ${({ theme }) => theme.colors.brand};
    }
  }

  &:hover {
    opacity: 1;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.brand};

    ${StyledIconWrapper} {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`

export const StyledTikTokIconSVG = styled(TikTokSVG)`
  margin-right: 8px;
`

export const StyledFacebookIconSVG = styled(FacebookSVG)`
  margin-right: 8px;
`

export const StyledInstagramIconSVG = styled(InstagramSVG)`
  margin-right: 8px;
`

export const StyledTwitterXIconSVG = styled(TwitterXSVG)`
  margin-right: 8px;
`

export const StyledATag = styled.a`
  all: unset;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.brand};
  opacity: 0.8;

  &:hover {
    opacity: 1;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const StyledNewTabLink = (props: LinkProps) => (
  <StyledLinkWithIcon as={NewTabLink} {...props} />
)

export const SocialContainer = styled(ContactContainer)`
  display: flex;
  flex-direction: column;
`
export const PolicyContainer = styled(ContactContainer)`
  display: flex;
  flex-direction: column;
`

export const ButtonsContainer = styled(ContactContainer)`
  display: flex;
  flex-direction: column;
`

export const StyledAppStore = styled(AppStoreSVG)`
  cursor: pointer;
`
export const StyledPlayStore = styled(PlayStoreSVG)`
  cursor: pointer;
`

export const AppButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const AppButtonContainer = styled(NewTabLink)`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  overflow: hidden;
`

export const PhoneTextLink = styled.a`
  margin: 0px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mainText};

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.brand};
  }
`
