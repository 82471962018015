import { makeVar } from '@apollo/client'

import { DetailedPaymentMethod } from '@src/graphql-types'

export type DetailedPaymentMethodWithCard =
  | {
      detailedPaymentMethod: DetailedPaymentMethod.CASH
    }
  | {
      detailedPaymentMethod: DetailedPaymentMethod.CARD_IN_PERSON
    }
  | {
      detailedPaymentMethod: DetailedPaymentMethod.WALLET
    }
  | {
      detailedPaymentMethod: DetailedPaymentMethod.CARD
      cardPaymentToken: string
    }
  | {
      detailedPaymentMethod: DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD
    }

export const paymentMethodVar = makeVar<DetailedPaymentMethodWithCard>({
  detailedPaymentMethod: DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD,
})
