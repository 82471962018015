import { useReactiveVar } from '@apollo/client'
import React from 'react'

import { LoyaltyCardStamp } from '@src/components/SingleOutlet/types'
import {
  DetailedPaymentMethod,
  Discount,
  LoyaltyCard as LoyaltyCardType,
} from '@src/graphql-types'
import { MainRouteName } from '@src/hooks/useAccountRouter'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { UseBasketTotalsResponseData } from '@src/hooks/useTotals/useBasketTotals'
import { paymentMethodVar } from '@src/hooks/useTotals/utils/paymentMethodReactiveVar'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  LoyaltyCardLinkContainer,
  LoyaltyCardImage,
  LoyaltyCardIconAndTextContainer,
  DescriptionAndStampsContainer,
  LoyaltyCardInnerContainer,
  LoyaltyCardTitle,
  OuterContainer,
} from './CustomerLoyaltyCard.styles'
import { LoyaltyCardInfoText } from './LoyaltyCardInfoText'
import { LoyaltyCardStampProgressMeter } from './StampProgressMeter'
import { amountToSpendToEarnAStamp } from './utils/calculate-amount-to-spend-to-earn-a-stamp'

import { ScallopedMaskSVG } from '../SVGS/ScallopedMaskSVG'

export type CustomerLoyaltyCard = Pick<
  LoyaltyCardType,
  | 'id'
  | 'requiredStamps'
  | 'discountStrategy'
  | 'allowCashOrders'
  | 'canStampBeEarnedToday'
  | 'loyaltyCardColor'
  | 'loyaltyCardIcon'
  | 'netTotal'
> & {
  discount: Pick<
    Discount,
    | 'id'
    | 'minimumSubtotalGross'
    | 'name'
    | 'discountAmount'
    | 'discountPercentage'
  >
} & { loyaltyCardStamps: LoyaltyCardStamp[] }

export const LoyaltyCard: React.FC<{
  loyaltyCard: CustomerLoyaltyCard | null
  isTermsPage?: boolean
  basketTotals?: UseBasketTotalsResponseData
  singleBasketCard?: boolean
}> = ({
  loyaltyCard,
  isTermsPage = false,
  basketTotals,
  singleBasketCard = false,
}) => {
  const formatCurrency = useFormatCurrency()
  const paymentMethod = useReactiveVar(paymentMethodVar)

  if (!loyaltyCard) {
    return null
  }

  const { discount } = loyaltyCard
  const loyaltyCardStamps = loyaltyCard.loyaltyCardStamps || []
  const amountToSpend =
    loyaltyCardStamps.length < loyaltyCard.requiredStamps
      ? amountToSpendToEarnAStamp({
          discount: discount,
          basketTotals: basketTotals,
          discountStrategy: loyaltyCard.discountStrategy,
        })
      : 0

  const canStampBeEarned = basketTotals
    ? amountToSpend === 0 &&
      (loyaltyCard.allowCashOrders ||
        paymentMethod.detailedPaymentMethod !== DetailedPaymentMethod.CASH)
    : loyaltyCard.canStampBeEarnedToday ?? true

  return (
    <OuterContainer>
      <LoyaltyCardLinkContainer
        singleBasketCard={singleBasketCard}
        loyaltyCardColor={loyaltyCard.loyaltyCardColor}
        route={{
          mainRouteName: MainRouteName.LOYALTY,
          pageDataId: discount?.id,
        }}
      >
        <LoyaltyCardInnerContainer>
          <LoyaltyCardIconAndTextContainer>
            <ScallopedMaskSVG scale={1} />
            <LoyaltyCardImage
              isTermsPage={false}
              role="img"
              aria-label={discount?.name}
              canStampBeEarned={canStampBeEarned}
              imageUrl={imageJitURL(loyaltyCard.loyaltyCardIcon, {
                resize: {
                  width: 168,
                  height: 168,
                  fit: 'cover',
                },
              })}
              scale={1}
            />
            <DescriptionAndStampsContainer isTermsPage={isTermsPage}>
              <LoyaltyCardTitle>{discount?.name}</LoyaltyCardTitle>
              <LoyaltyCardInfoText
                loyaltyCard={loyaltyCard}
                basketTotals={basketTotals}
                isTermsPage={isTermsPage}
                amountToSpendToEarnStamp={formatCurrency(amountToSpend)}
                paymentMethod={paymentMethod.detailedPaymentMethod}
              />
              <LoyaltyCardStampProgressMeter
                isOutletList={false}
                loyaltyCard={loyaltyCard}
                canStampBeEarned={canStampBeEarned}
                basketTotals={basketTotals}
              />
            </DescriptionAndStampsContainer>
          </LoyaltyCardIconAndTextContainer>
        </LoyaltyCardInnerContainer>
      </LoyaltyCardLinkContainer>
    </OuterContainer>
  )
}
