import styled from 'styled-components'

import { CouponSVG } from '@src/components/SVGS/CouponSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const MenuItemTable = styled.div`
  width: 100%;
  margin: 8px 0 4px 0;
`

export const MenuItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 16px;
  padding: 8px 16px;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}px) {
    gap: 8px;
  }
`

export const MenuItemName = styled.div`
  font-weight: 700;
  font-size: 14px;

  @media (max-width: ${breakpoints.tablet}px) {
    margin-left: 10px;
  }
`

export const MenuItemQuantity = styled.div`
  font-weight: 400;
  width: 40px; // fixed this width to keep each item quantity (x2 etc) uniform and inline
  text-align: right;

  @media (min-width: ${breakpoints.largeTablet}px) {
    width: 50px;
  }
`

export const MenuItemPrice = styled.div`
  font-weight: 400;
  display: flex;
  align-items: center;
  width: 80px; // same here
  justify-content: flex-end;
`

export const OptionItemSubContainer = styled(MenuItemsContainer)`
  padding: 4px 12px;
  border-bottom: none;
`

export const OptionItemName = styled.div`
  font-size: 12px;
  margin-left: 16px;

  @media (max-width: ${breakpoints.tablet}px) {
    margin-left: 28px;
  }
`

export const OptionItemSpacer = styled.div`
  width: 40px;
  @media (min-width: ${breakpoints.largeTablet}px) {
    width: 50px;
  }
`

export const OptionItemPrice = styled.div`
  font-size: 12px;
  width: 80px;
  text-align: right;
`

export const StyledCouponSVG = styled(CouponSVG)`
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  color: #2e3333;
`

export const OptionItemsContainer = styled.div`
  width: 100%;
`
