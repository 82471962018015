import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { animated, useTransition } from 'react-spring'
import { useQueryParams, withDefault, StringParam } from 'use-query-params'

import {
  Container,
  Title,
  Text,
  DialogInner,
  DialogOverlay,
  DialogOverlayInner,
  StyledButton,
  HeaderContainer,
  MainContainer,
  SubTitle,
  StyledCloseButton,
  StyledXSVG,
  LoadingContainer,
} from './AlertModal.styles'

import { ButtonType } from '../Button/ButtonTypesEnum'
import { LoadingSpinner } from '../LoadingSpinner'

// This modal styling is only for modal-within-modal alerts, but can be adapted if needed

type Outcome = {
  text?: string
  intent?: ButtonType
  onClick: () => void
  isDanger?: boolean
  isLoading?: boolean
}

export const AlertModal: React.VFC<{
  isOpen: boolean
  title: string
  subTitle?: string | JSX.Element
  desc?: string
  action: Outcome
  cancel?: Outcome
  onClose?: () => void
  headerBackgroundColor?: string
  showCloseButton?: boolean
  closeTriggersCancel?: boolean
  isLoading?: boolean
  isLeftSidebar?: boolean
  dataTestId?: string
}> = ({
  isOpen,
  title,
  subTitle,
  desc,
  action,
  cancel,
  onClose = cancel?.onClick,
  headerBackgroundColor = 'white',
  showCloseButton,
  isLoading,
  isLeftSidebar,
  dataTestId,
}) => {
  const { t } = useTranslation('common')
  const modalRoot = document.getElementById('modal-root')

  if (!modalRoot) {
    throw new Error('Modal root not found')
  }

  const [showContent, setShowContent] = useState(false)
  const [query] = useQueryParams({
    checkout: withDefault(StringParam, ''),
  })
  const isCheckoutPage = !!query.checkout

  const closeModal = () => {
    if (showContent) {
      setShowContent(false)
      setTimeout(() => {
        onClose && onClose()
      }, 200)
    }
  }

  useEffect(() => {
    setShowContent(isOpen)
  }, [isOpen])

  const overlayTransitions = useTransition(showContent, {
    from: { opacity: 0 },
    enter: { opacity: 0.7 },
    leave: { opacity: 0 },
  })

  const modalTransitions = useTransition(showContent, {
    from: { opacity: 0, transform: 'translateY(-20px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(200px)' },
    config: { mass: 1, tension: 500, friction: 18 },
  })

  const AnimatedDialogOverlay = animated(DialogOverlay)
  const AnimatedOverlayInner = animated(DialogOverlayInner)

  return createPortal(
    <DialogInner isCheckoutPage={isCheckoutPage}>
      {overlayTransitions(
        (styles, item) => item && <AnimatedDialogOverlay style={styles} />
      )}

      {modalTransitions(
        (styles, item) =>
          item && (
            <AnimatedOverlayInner style={styles} isLeftSidebar={isLeftSidebar}>
              <Container>
                {isLoading ? (
                  <LoadingContainer>
                    <LoadingSpinner text={t('please_wait')} />
                  </LoadingContainer>
                ) : (
                  <>
                    <HeaderContainer $backgroundColor={headerBackgroundColor}>
                      {showCloseButton && (
                        <StyledCloseButton
                          id="close"
                          aria-label={t('close_alert')}
                          onClick={closeModal}
                        >
                          <StyledXSVG id="close" />
                        </StyledCloseButton>
                      )}
                      <Title>{title}</Title>
                      {subTitle && <SubTitle>{subTitle}</SubTitle>}
                    </HeaderContainer>
                    <MainContainer isTallContainer={!!desc}>
                      {desc && <Text>{desc}</Text>}

                      <StyledButton
                        loading={action.isLoading}
                        buttonType={
                          action.isDanger
                            ? ButtonType.DANGER
                            : action.intent ?? ButtonType.PRIMARY
                        }
                        content={action.text ?? t('yes')}
                        onClick={action.onClick}
                        dataTestId={dataTestId}
                      />
                      {cancel && (
                        <StyledButton
                          loading={cancel.isLoading}
                          buttonType={
                            cancel.isDanger
                              ? ButtonType.DANGER
                              : cancel.intent ?? ButtonType.SECONDARY
                          }
                          content={cancel.text ?? t('no')}
                          onClick={cancel.onClick}
                        />
                      )}
                    </MainContainer>
                  </>
                )}
              </Container>
            </AnimatedOverlayInner>
          )
      )}
    </DialogInner>,
    modalRoot
  )
}
