import { Discount, LoyaltyCardDiscountStrategy } from '@src/graphql-types'
import { UseBasketTotalsResponseData } from '@src/hooks/useTotals/useBasketTotals'

interface AmountToSpendToEarnStamp {
  basketTotals?: UseBasketTotalsResponseData
  discount?: Pick<Discount, 'id' | 'minimumSubtotalGross'>
  discountStrategy: LoyaltyCardDiscountStrategy
}

export const amountToSpendToEarnAStamp = ({
  basketTotals,
  discount,
  discountStrategy,
}: AmountToSpendToEarnStamp): number => {
  if (!basketTotals?.checkoutBasketTotals) {
    return 0
  }

  const { appliedDiscount } = basketTotals.checkoutBasketTotals || {}
  const basketItemsTotal = basketTotals.basketItemsTotal || 0
  const discountAmount =
    appliedDiscount?.discount?.discountAmount ||
    (basketTotals.basketItemsTotal / 100) *
      (appliedDiscount?.discount.discountPercentage ?? 1)

  const loyaltyCardDiscount =
    appliedDiscount?.discount?.loyaltyCard?.netTotal || 0

  const appliedDiscountId = appliedDiscount?.discount?.id
  // check if the basket subtotal is below the minimum required subtotal for the discount
  if (
    appliedDiscountId === discount?.id &&
    discount?.minimumSubtotalGross &&
    basketItemsTotal < discount.minimumSubtotalGross
  ) {
    return discount.minimumSubtotalGross - basketItemsTotal > 0
      ? discount.minimumSubtotalGross - basketItemsTotal
      : 0
  }

  if (discountStrategy === 'BASKET_SUBTOTAL_DISCOUNT') {
    // If the loyalty card has a basket subtotal discount strategy
    //  check that any applied discount isn't bigger than the subtotal so that the stamp value isn't zero
    // Loyalty card discounts work the same as a fixed discount, so we use the net total
    if (loyaltyCardDiscount > 0) {
      if (basketItemsTotal < loyaltyCardDiscount) {
        return loyaltyCardDiscount - basketItemsTotal
      }

      // if the basket subtotal is exactly the same as the amount of discount taken off
      // you should still have to spend more because the value of the stamp is still zero
      if (basketItemsTotal === loyaltyCardDiscount) {
        return 1
      }
    }

    // if its a regular discount passed through, check the discount amount
    if (discountAmount > 0) {
      if (basketItemsTotal < discountAmount) {
        return discountAmount - basketItemsTotal
      }
      if (basketItemsTotal === discountAmount) {
        return 1
      }
    }
  }

  const totalToUse =
    discountStrategy === 'BASKET_SUBTOTAL_DISCOUNT'
      ? basketTotals.checkoutBasketTotals.nonAddOnBasketItemsTotal -
        (basketTotals.checkoutBasketTotals.appliedDiscount?.reductionAmount ??
          0)
      : basketTotals.checkoutBasketTotals.sumTotal

  if (appliedDiscountId !== discount?.id) {
    return (discount?.minimumSubtotalGross ?? 0) - totalToUse > 0
      ? (discount?.minimumSubtotalGross ?? 0) - totalToUse
      : 0
  }

  //no extra spend needed
  return 0
}
