import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button, ButtonType } from '@src/components/Button/Button'
import { LoaderCheck } from '@src/components/Check/LoaderCheck'
import { TextInput } from '@src/components/Inputs/TextInput/TextInput'
import { Redirect } from '@src/components/Router/Redirect'
import { jwtVar } from '@src/models/customer/jwt'

import { resetPasswordDocument } from './mutations/__generated__/resetPassword.graphql-interface'
import { ResponseContainer, Subtitle } from './NewPasswordForm.styles'
import { enterNewPasswordSchema } from './validation/passwordResetStructure'

export const NewPasswordForm: React.VFC = () => {
  const navigate = useNavigate()

  const [resetPassword, { data, loading, error }] = useMutation(
    resetPasswordDocument,
    {
      onCompleted(data) {
        if (data.resetPassword.message === 'password_updated') {
          // set the jwt token in local storage - logging the user in
          jwtVar(data.resetPassword.token)

          setTimeout(() => {
            navigate('/?account=details')
          }, 3000)
        }
      },
    }
  )
  const { t } = useTranslation('resetPassword')
  const [{ cid: customerId, token }] = useQueryParams({
    cid: StringParam,
    token: StringParam,
  })

  if (!customerId || !token) {
    return <Redirect to="/" passthroughQueryParams={false} />
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        confirmPassword: '',
      }}
      validateOnBlur={true}
      validationSchema={toFormikValidationSchema(enterNewPasswordSchema)}
      onSubmit={async values => {
        await resetPassword({
          variables: {
            customerId,
            token,
            newPassword: values.password,
          },
        })
      }}
    >
      <Form>
        <div>
          {data?.resetPassword?.message ? (
            <ResponseContainer>
              <LoaderCheck />
              <Subtitle>{t(data.resetPassword.message)}</Subtitle>
            </ResponseContainer>
          ) : (
            <Fragment>
              <p>{t('password_reset')}</p>
              <div>
                <TextInput
                  type="password"
                  name="password"
                  label={t('password')}
                />
              </div>
              <div>
                <TextInput
                  type="password"
                  name="confirmPassword"
                  label={t('confirm_password')}
                />
              </div>
            </Fragment>
          )}

          {error && (
            <ResponseContainer error>{t(error.message)}</ResponseContainer>
          )}

          {data?.resetPassword?.message !== 'password_updated' && (
            <Button
              content={t('update_password')}
              buttonType={ButtonType.PRIMARY}
              type="submit"
              loading={loading}
            />
          )}
        </div>
      </Form>
    </Formik>
  )
}
