import React from 'react'
import { Navigate, Route } from 'react-router-dom'

import { OrderMode } from '@src/graphql-types'
import { OutletList } from '@src/pages/OutletList/OutletList'

const listModeRoutes = (merchantURLPath: string, deliveryZone?: string) => (
  <>
    <Route
      key="1"
      path={`/:deliveryZone/:merchantCategory`}
      element={<OutletList deliveryZone={deliveryZone} />}
    />
    ,
    <Route
      key="2"
      path={`/:deliveryZone/:merchantCategory`}
      element={<Navigate to={`/${deliveryZone}/${merchantURLPath}`} />}
    />
    ,
  </>
)

const postcodeModeRoutes = (merchantURLPath: string) => (
  <>
    <Route path={`/:merchantCategory`} element={<OutletList />} />

    <Route
      path={`/:merchantCategory`}
      element={<Navigate to={`/${merchantURLPath}`} />}
    />
  </>
)

export const outletListRoutes = ({
  merchantURLPath,
  orderMode,
  deliveryZone,
}: {
  merchantURLPath: string
  orderMode: OrderMode
  deliveryZone?: string
}): JSX.Element => {
  switch (orderMode) {
    case OrderMode.LIST:
      return listModeRoutes(merchantURLPath, deliveryZone)
    case OrderMode.POSTCODE:
      return postcodeModeRoutes(merchantURLPath)
    default:
      return <></>
  }
}
