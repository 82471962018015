import { useQuery } from '@apollo/client'
import React from 'react'

import { LoadingSpinner } from '@src/components/LoadingSpinner'
import {
  NarrowFulfilmentMethodInputType,
  MenuItemGroupFulfillmentMethod,
} from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useBasketTotals } from '@src/hooks/useTotals/useBasketTotals'

import { AddOnItems } from './AddOnItems'
import { addOnMenuItemsDocument } from './queries/__generated__/addOnMenuItems.graphql-interface'

// TODO sorry i feel like this should already exist but i cant find it
const ChosenFulfilmentToMenuItemGroupFulfillmentMethod: Record<
  NarrowFulfilmentMethodInputType,
  MenuItemGroupFulfillmentMethod
> = {
  [NarrowFulfilmentMethodInputType.DELIVERY]:
    MenuItemGroupFulfillmentMethod.DELIVERY,
  [NarrowFulfilmentMethodInputType.COLLECTION]:
    MenuItemGroupFulfillmentMethod.COLLECTION,
  [NarrowFulfilmentMethodInputType.TABLE]: MenuItemGroupFulfillmentMethod.TABLE,
}

export const AddOnItemsEl: React.FC = () => {
  const basketTotals = useBasketTotals()
  const {
    outlet,
    data: { currentFulfilment },
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const checkoutRouter = useCheckoutRouter()

  const { data, loading } = useQuery(
    addOnMenuItemsDocument,
    basketTotals.data?.checkoutBasketTotals
      ? {
          variables: {
            outletId: outlet.id,
            subTotal:
              basketTotals.data.checkoutBasketTotals.nonAddOnBasketItemsTotal,
            fulfilmentMethods: [
              ChosenFulfilmentToMenuItemGroupFulfillmentMethod[
                currentFulfilment.narrowType
              ],
            ],
            endOfPrep: currentFulfilment.endOfPrep?.toISOString(),
          },
          onCompleted: data => {
            if (!data.addOnMenuItems.length) {
              checkoutRouter.override(CheckoutRoute.PAYMENT)
            }
          },
          onError: _error => {
            checkoutRouter.override(CheckoutRoute.PAYMENT)
          },
        }
      : { skip: true }
  )

  if (loading || !basketTotals.data?.checkoutBasketTotals) {
    return <LoadingSpinner />
  }

  if (!data || !data.addOnMenuItems.length) {
    return null
  }

  const { name: restaurantName } = outlet.restaurant
  return (
    <>
      <AddOnItems
        restaurantName={restaurantName}
        addOnItems={data.addOnMenuItems}
      />
    </>
  )
}
