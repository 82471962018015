import { useQuery } from '@apollo/client'
import { groupBy } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { BottomSheetWrapper } from '@src/components/BottomSheetWrapper/BottomSheetWrapper'
import { CaretLeft } from '@src/components/SVGS/CaretLeft'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useFindMenuItemModalData } from '@src/hooks/useFindMenuItemModalData'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useMenuItemQueryParam } from '@src/hooks/useMenuItemQueryParam'
import { convertToSlug } from '@src/utils/convertToSlug'

import {
  BackToOutletContainer,
  BackToOutletTitle,
  MenuGroupContainer,
  StackedMenuViewContainer,
} from './OutletStackedMenu.styles'

import { OutletStackedMenuItemGroupTree } from '../menuItemGroupTreeType'
import { MenuGroup } from '../OutletMenu/MenuGroup/MenuGroup'
import { MenuItemModal } from '../OutletMenu/MenuItemModal/MenuItem.modal'
import { GetSingleOutletMenuDocument } from '../OutletMenu/queries/__generated__/GetSingleOutletMenu.graphql-interface'

export const OutletStackedMenuView: React.FC<{
  activeMenuId: string | null
  setActiveMenuId: (val: string | null) => void
  manualNavigation: boolean
}> = ({ activeMenuId, setActiveMenuId, manualNavigation }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('outletOverview')
  const {
    data: { currentFulfilment },
    outlet,
  } = useOutletFulfilment({ stateType: OutletFulfilmentStateType.GLOBAL })

  const { urlPath } = useMarketplace()
  const { menuGroupId } = useParams<{ menuGroupId: string }>()
  const [modalMenuItemId, setMenuItemQueryParam] = useMenuItemQueryParam()

  const menuItemGroupId = menuGroupId?.split(':')[1]
  const { data, previousData } = useQuery(
    GetSingleOutletMenuDocument,
    menuItemGroupId
      ? {
          variables: {
            menuItemGroupId: menuItemGroupId,
            outletId: outlet.id,
            narrowFulfilmentMethods: currentFulfilment.narrowType,
          },
        }
      : { skip: true }
  )

  const menuItemGroups = (data || previousData)?.singleMenuItemGroupForOutlet

  const menuItemGroupTree = useMemo<OutletStackedMenuItemGroupTree>(() => {
    if (!menuItemGroups) {
      return []
    }
    const menuItemsWithoutAddonItems = menuItemGroups.filter(
      ({ addOnItemsMenu }) => !addOnItemsMenu
    )
    const { parentMenus = [], subMenus = [] } = groupBy(
      menuItemsWithoutAddonItems,
      menuItemGroup => (menuItemGroup.parentMenu ? 'subMenus' : 'parentMenus')
    )
    // nest submenus under parent menus
    return parentMenus.map(parentMenu => {
      const subMenusForParentMenu = subMenus.filter(
        subMenu => subMenu.parentMenu?.id === parentMenu.id
      )
      return {
        parentMenu,
        subMenus: subMenusForParentMenu,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    menuItemGroups,
    currentFulfilment.endOfPrep,
    currentFulfilment.narrowType,
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { modalData } = useFindMenuItemModalData({
    fullMenuItemGroupTree: menuItemGroupTree,
    modalMenuItemId,
  })

  return (
    <>
      <StackedMenuViewContainer>
        <BackToOutletContainer
          onClick={() =>
            navigate(
              `/${urlPath}/${outlet.id}/${convertToSlug(
                outlet.displayName
              )}/menu`,
              { replace: true }
            )
          }
        >
          <CaretLeft id={'caretLeft'} />
          <BackToOutletTitle>
            {t('back_to_outlet_page', {
              outletName: outlet.displayName,
            })}
          </BackToOutletTitle>
        </BackToOutletContainer>
        <MenuGroupContainer>
          {menuItemGroupTree.map(outletMenuGroup => {
            return (
              // make sure there are menu items in either the parent menu or submenus
              outletMenuGroup.parentMenu.outletMenuItems.length > 0 ||
                (outletMenuGroup.subMenus[0] &&
                  outletMenuGroup.subMenus[0].outletMenuItems.length > 0) ? (
                <MenuGroup
                  manualNavigation={manualNavigation}
                  key={outletMenuGroup.parentMenu.id}
                  outletId={outlet.id}
                  outletParentMenu={outletMenuGroup.parentMenu}
                  outletSubMenus={outletMenuGroup.subMenus}
                  activeMenuId={activeMenuId}
                  setActiveMenuId={setActiveMenuId}
                />
              ) : null
            )
          })}
        </MenuGroupContainer>
      </StackedMenuViewContainer>

      <BottomSheetWrapper
        open={!!modalMenuItemId}
        onClose={() => {
          setMenuItemQueryParam(undefined, 'replaceIn')
        }}
        headerColor="#fff"
        backgroundColor="#fff"
      >
        {modalData && (
          <MenuItemModal
            modalData={modalData}
            closeModal={() => {
              setMenuItemQueryParam(undefined, 'replaceIn')
            }}
          />
        )}
      </BottomSheetWrapper>
    </>
  )
}
