import { addMinutes, isBefore, max, min } from 'date-fns'

import { Outlet } from '@src/graphql-types'
import {
  FulfilmentFilterWhenType,
  FulfilmentFilterWhenPreorder,
  FulfilmentFilterWhenAnytime,
  FulfilmentFilterWhenASAP,
} from '@src/hooks/useFulfilmentFilter/validation'
import { DateifyOutlet } from '@src/utils/fulfilmentTimes/parsers'
import { getAvailableFulfilmentsByFulfilmentMethod } from '@src/utils/getAvailableFulfilmentsByFulfilmentMethod'

// calculates the collection preorder time from the current fulfilment filter
// (attempts to find the first available time today or on the date selected by the customer)
// returns null if it should be asap or there are no available times
export const calculateCollectionPreorderDateTime = ({
  outlet,
  fulfilmentFilterWhen,
}: {
  outlet: Pick<
    DateifyOutlet<Outlet>,
    | 'prepTime'
    | 'availableFulfilments'
    | 'isOpen'
    | 'nextOpenDate'
    | 'collectionPreorderTimes'
  >
  fulfilmentFilterWhen:
    | Zod.infer<typeof FulfilmentFilterWhenAnytime>
    | Zod.infer<typeof FulfilmentFilterWhenASAP>
    | Zod.infer<typeof FulfilmentFilterWhenPreorder>
}): Date | null => {
  const availableFulfilmentsByFulfilmentMethod =
    getAvailableFulfilmentsByFulfilmentMethod(outlet.availableFulfilments)

  if (
    // customer selected preorder and outlet offers preorders
    (fulfilmentFilterWhen.type === FulfilmentFilterWhenType.PREORDER &&
      availableFulfilmentsByFulfilmentMethod.COLLECTION.PREORDER) ||
    // customer selected ANYTIME or ASAP but asap is not available
    ([FulfilmentFilterWhenType.ANYTIME, FulfilmentFilterWhenType.ASAP].includes(
      fulfilmentFilterWhen.type
    ) &&
      !availableFulfilmentsByFulfilmentMethod.COLLECTION.ASAP)
  ) {
    // calculate best preorder window based on customer's selected preorder date / now + prep / closeduntil + prep
    const earliestPossiblePreorderTime = addMinutes(
      outlet.isOpen ? new Date() : outlet.nextOpenDate,
      outlet.prepTime
    )
    const earliestPreorderTime =
      fulfilmentFilterWhen.type === FulfilmentFilterWhenType.PREORDER
        ? max([fulfilmentFilterWhen.preorderDate, earliestPossiblePreorderTime])
        : earliestPossiblePreorderTime
    const availablePreorderTimes = outlet.collectionPreorderTimes.filter(
      collectionPreorderTime =>
        !isBefore(collectionPreorderTime, earliestPreorderTime)
    )

    return availablePreorderTimes.length > 0
      ? min(availablePreorderTimes)
      : null
  }

  // default to asap
  return null
}
