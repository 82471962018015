import React from 'react'
import { useTranslation } from 'react-i18next'

import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { Helmet } from '@src/components/Helmet'
import { useCustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import {
  AddressesChildName,
  MainRouteName,
  useAccountRouter,
} from '@src/hooks/useAccountRouter'
import { useNotLoggedInRedirect } from '@src/hooks/useNotLoggedInRedirect'

import { AddressSkeleton } from './Address/Address'
import { AddAddressButton, StyledCirclePlusSVG } from './AddressBook.styles'
import { AddressList } from './AddressList'

const SKELETON_COUNT = 3
const skeletonKeys = [...Array(SKELETON_COUNT).keys()]

const AddressBookContent: React.FC = () => {
  const { data, loading, error } = useCustomerDetailsAndAddressesQuery()

  const { t } = useTranslation('addressBook')
  const { setRoute } = useAccountRouter()

  if (loading) {
    return (
      <>
        {skeletonKeys.map(key => (
          <AddressSkeleton key={key} />
        ))}
      </>
    )
  }

  if (error || !data) {
    return <ErrorPage errorMessage={t('error')} />
  }

  if (!data.customerDetails.deliveryAddresses.length) {
    setRoute({
      mainRouteName: MainRouteName.ADDRESSES,
      childRouteName: AddressesChildName.ADD,
    })
  }

  return (
    <>
      <AddAddressButton
        route={{
          mainRouteName: MainRouteName.ADDRESSES,
          childRouteName: AddressesChildName.ADD,
        }}
        dataTestId="add-address-button"
      >
        <>
          {t('add_address')}
          <StyledCirclePlusSVG id="add_address_plus" title={t('add_address')} />
        </>
      </AddAddressButton>

      <AddressList customerDetails={data.customerDetails} />
    </>
  )
}

export const AddressBook: React.FC = () => {
  useNotLoggedInRedirect()
  const { t } = useTranslation(['addressBook'])

  return (
    <>
      <Helmet title={t('title')} />
      <BurgerSection header={{ title: t('title') }}>
        <AddressBookContent />
      </BurgerSection>
    </>
  )
}
