import { useReactiveVar } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CategorySlide } from '@src/components/BusinessSegments/CategorySlide'
import { SegmentCategoriesContainer } from '@src/components/BusinessSegments/CategorySlide.styles'
import { Carousel } from '@src/components/Carousel'
import { TextClamp } from '@src/components/Text/Clamp'
import { BusinessSegment, Cuisine } from '@src/graphql-types'
import { screenResolutionVar } from '@src/models/screenResolution'

import { breakpoints } from '../../constants/breakpoints'
import { IconButton } from '../Button/IconButton'
import { CategoriesContent } from '../CategoriesModal/CategoriesContent'
import {
  HeroContainer,
  OutletContainer,
  SliderArrowsContainer,
  Description,
  BusinessSegmentCategoriesHeader,
} from '../FeaturedOutlets/FeaturedOutlets.styles'
import { ServicePopover } from '../ServicePopover/ServicePopover'

type Props = {
  segment: Pick<BusinessSegment, 'id' | 'name' | 'showName' | 'description'> & {
    cards: Cuisine[]
  }
}

export const BusinessSegmentCategories: React.FC<Props> = ({ segment }) => {
  const { t } = useTranslation('navigation')

  const segmentCategories = segment.cards
  const [showCategories, setShowCategories] = useState(false)
  const { width } = useReactiveVar(screenResolutionVar)

  const circleButtonLeftClassName = `${segment.id}-segment-left`
  const circleButtonRightClassName = `${segment.id}-segment-right`
  return (
    <>
      {segmentCategories?.length > 0 && (
        <OutletContainer>
          <ServicePopover
            open={showCategories}
            onClose={() => setShowCategories(false)}
            height={width < breakpoints.tablet ? 0.81 : 0.84}
            maxWidth={860}
          >
            <CategoriesContent setShowCategories={setShowCategories} />
          </ServicePopover>
          <HeroContainer>
            <BusinessSegmentCategoriesHeader
              onClick={() => setShowCategories(true)}
            >
              {segment.showName && segment.name}
            </BusinessSegmentCategoriesHeader>
            <SliderArrowsContainer>
              <IconButton
                className={circleButtonLeftClassName}
                size={32}
                icon={'caretLeft'}
                alt={t('left')}
              />
              <IconButton
                className={circleButtonRightClassName}
                size={32}
                icon={'caretRight'}
                alt={t('right')}
              />
            </SliderArrowsContainer>
          </HeroContainer>
          {!!segment.description?.length && (
            <Description>
              <TextClamp clamp={5}>{segment.description}</TextClamp>
            </Description>
          )}
          <SegmentCategoriesContainer>
            <Carousel
              buttonLeftClassName={circleButtonLeftClassName}
              buttonRightClassName={circleButtonRightClassName}
              slides={segmentCategories.map(category => (
                <CategorySlide
                  key={`segmentCategory-${segment.id}-${category.id}`}
                  category={category}
                />
              ))}
            />
          </SegmentCategoriesContainer>
        </OutletContainer>
      )}
    </>
  )
}
