import { cloneDeep } from 'lodash'

import {
  AvailableFulfilment,
  FulfilmentTimeType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'

type AvailableFulfilmentsByFulfilmentMethod = Record<
  NarrowFulfilmentMethodInputType,
  Record<FulfilmentTimeType, boolean>
>

const DEFAULT_STATE: AvailableFulfilmentsByFulfilmentMethod = {
  [NarrowFulfilmentMethodInputType.DELIVERY]: {
    [FulfilmentTimeType.ASAP]: false,
    [FulfilmentTimeType.PREORDER]: false,
  },
  [NarrowFulfilmentMethodInputType.COLLECTION]: {
    [FulfilmentTimeType.ASAP]: false,
    [FulfilmentTimeType.PREORDER]: false,
  },
  [NarrowFulfilmentMethodInputType.TABLE]: {
    [FulfilmentTimeType.ASAP]: false,
    [FulfilmentTimeType.PREORDER]: false,
  },
}

export const getAvailableFulfilmentsByFulfilmentMethod = (
  availableFulfilments: AvailableFulfilment[]
) => {
  return availableFulfilments.reduce<AvailableFulfilmentsByFulfilmentMethod>(
    (acc, availableFulfilment) => {
      if (
        availableFulfilment.fulfilmentMethod ===
        NarrowFulfilmentMethodInputType.TABLE
      ) {
        acc.TABLE.ASAP = true
        acc.TABLE.PREORDER = true
      }
      for (const fulfilmentTimeType of availableFulfilment.fulfilmentTimeTypes) {
        acc[availableFulfilment.fulfilmentMethod][fulfilmentTimeType] = true
      }
      return acc
    },
    cloneDeep(DEFAULT_STATE)
  )
}
