import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { toast } from 'react-toastify'

import { localStorageKeys } from '@src/constants/localStorageKeys'
import i18n from '@src/i18n/i18n'

import { apolloTypePolicies } from './apolloTypePolicies/apolloTypePolicies'
import { apiUrlV2 } from './config/urls'
import { jwtVar } from './models/customer/jwt'
import { graphqlErrorGrouper } from './utils/apolloErrorParser/apolloErrorParser'

const TRACE_GQL = import.meta.env.DEV

const authLink = setContext((_, { headers }) => {
  let token = localStorage.getItem(localStorageKeys.jwt)

  // v1 website json.stringify's the token, but v2 does not
  if (token && token.startsWith('"') && token.endsWith('"')) {
    token = JSON.parse(token) as string
    localStorage.setItem(localStorageKeys.jwt, token)
  }

  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : undefined),
      ['apollographql-client-version']: '2.0.0',
      ['apollographql-client-name']: 'customer-web',
    },
  }
})

const httpLink = new HttpLink({ uri: apiUrlV2 })

const batchLink = new BatchHttpLink({
  uri: import.meta.env.DEV
    ? ({ operationName, variables }) => {
        return `${apiUrlV2}?op=${operationName}&vars=${Object.values(
          variables
        ).join(',')}`
      }
    : apiUrlV2,
  batchMax: 50,
  batchInterval: 100,
})

const toastUnexpectedError = (message: string) =>
  toast.error(i18n.t(message, { ns: 'common' }), {
    // prevent multiple unexpected error toasts
    toastId: 'unexpected_error',
  })

// Add error logging for all operations
const errorLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server

  const start = new Date().valueOf()

  const { query, variables } = operation
  const type: string =
    (query as any)?.definitions?.[0]?.operation?.toUpperCase?.() || 'UNKNOWN'
  const resolver: string =
    (query as any)?.definitions?.[0]?.selectionSet?.selections[0]?.name
      ?.value || ''

  const observable = forward(operation)

  return observable.map(data => {
    const time = new Date().valueOf() - start

    if (data.errors) {
      data.errors.forEach(err => {
        // eslint-disable-next-line no-console
        console.error(`[GQL ${type} ERROR] ${resolver}`, err.message)
      })
      const { unexpectedErrors, customAuthenticationErrors } =
        graphqlErrorGrouper(data.errors)
      const isAuthenticated = jwtVar() !== null
      // Unexpected auth errors should toast and log out the user
      // but only if they are authenticated, otherwise it would happen on a failed login attempt
      if (isAuthenticated && customAuthenticationErrors.length) {
        jwtVar(null)
        toastUnexpectedError(i18n.t('authentication_error', { ns: 'common' }))
      }

      // Unexpected errors are not handled by the UI and should be toasted
      if (unexpectedErrors.length) {
        toastUnexpectedError(i18n.t('unexpected_error', { ns: 'common' }))
      }
    }

    TRACE_GQL &&
      // eslint-disable-next-line no-console
      console.log(
        `[GQL ${type} @ ${time}ms] ${resolver}(${Object.entries(variables)
          .map(([k, v]) => `${k}: ${JSON.stringify(v)}`)
          .join(', ')})`
      )

    return data
  })
})

const link = new RetryLink().split(
  operation => {
    // Queries responsible for loading the initial app do not want to be batched as this blocks loading of the app use default HttpLink instead
    return operation.operationName === 'marketplaceCnameLookup'
  },
  httpLink,
  batchLink
)

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: apolloTypePolicies,
  }),
  link: authLink.concat(errorLink).concat(link),
  connectToDevTools: true,
})
