import { upperFirst } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SpinnerSVGStyled } from '@src/components/Inputs/CheckboxInput/CheckboxInput.styles'
import { CountryCodeType, MerchantType } from '@src/graphql-types'
import { LocationType } from '@src/graphql-types'
import { useFindLocation } from '@src/hooks/useFindLocation'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'

import {
  Container,
  Input,
  SearchButton,
  LocationButtonContainer,
  LocationButton,
  OuterContainer,
  StyledLocationSVG,
  TextHelp,
  ShowEverywhereText,
  HelpTextContainer,
} from './HomePostcodeInput.styles'

export const HomePostcodeInput: React.FC = () => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const fulfilmentFilter = useFulfilmentFilter()
  const [postcode, setPostcode] = useState(
    fulfilmentFilter.data.where.historicalData.postAndCountryCode?.postcode ||
      ''
  )
  const navigate = useNavigate()
  const findLocation = useFindLocation({
    onSuccess: coordinates => {
      fulfilmentFilter.setLocationType({
        type: LocationType.COORDINATES,
        coordinates,
      })
      setFindingLocation(false)
      navigate(`/${merchantURLPath}`)
    },
    onError: () => {
      setFindingLocation(false)
    },
  })
  const [findingLocation, setFindingLocation] = useState(false)
  const marketplace = useMarketplace()
  const merchantURLPath = marketplace.urlPath

  const { postcode: postcodePlaceholder } = marketplace.contactAddress

  const handlePostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const upperCaseValue = value.toUpperCase()
    setPostcode(upperCaseValue)
  }

  const handleFormSubmit = (): void => {
    setIsSubmitting(true)
    fulfilmentFilter.setLocationType({
      type: LocationType.POSTCODE,
      postAndCountryCode: {
        postcode,
        countryCode: marketplace.country.ISO3166Alpha2 as CountryCodeType,
      },
    })
    navigate(`/${merchantURLPath}`)
  }

  const handleShowEverywhere = (): void => {
    fulfilmentFilter.setLocationType({
      type: LocationType.EVERYWHERE,
    })
    navigate(`/${merchantURLPath}`)
  }

  const getSearchTerm = () => {
    // return merchantURL path if exists e.g. Florists
    if (merchantURLPath) return upperFirst(merchantURLPath)
    // else if restaurant, return 'Restaurants', if retail return 'Outlets'
    return marketplace.merchantType === MerchantType.RETAIL
      ? 'Outlets'
      : 'Restaurants'
  }

  return (
    <OuterContainer>
      <HelpTextContainer>
        <TextHelp>
          {t('search_near_me_help', {
            searchTerm: getSearchTerm().toLocaleLowerCase(),
          })}
          <ShowEverywhereText onClick={() => handleShowEverywhere()}>
            {t('show_everything')}
          </ShowEverywhereText>
          {'.'}
        </TextHelp>
      </HelpTextContainer>
      <Container onSubmit={handleFormSubmit}>
        <LocationButtonContainer>
          <LocationButton
            id={t('use_current_location')}
            onClick={() => {
              setFindingLocation(true)
              findLocation()
            }}
            type="button"
          >
            {findingLocation ? (
              <SpinnerSVGStyled id="locationLoadSVG" height={32} width={32} />
            ) : (
              <StyledLocationSVG id="postcode" />
            )}
          </LocationButton>
          <Input
            name="postal-code"
            autoComplete="home postal-code"
            required
            type="text"
            placeholder={t('enter_your_postcode', {
              postcode: postcodePlaceholder ?? 'SW1 1AA',
            })}
            onChange={handlePostcodeChange}
            value={postcode}
            title={t('enter_your_postcode_alt', {
              postcode: postcodePlaceholder ?? 'SW1 1AA',
            })}
          />
        </LocationButtonContainer>
        <SearchButton
          type="submit"
          disabled={postcode.length <= 2}
          onClick={() => handleFormSubmit()}
          content={t('search_near_me', { searchTerm: getSearchTerm() })}
          loading={isSubmitting}
        />
      </Container>
    </OuterContainer>
  )
}
