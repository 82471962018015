import styled, { css } from 'styled-components'

import { AccountSectionNavButton } from '../AccountSectionNavButton/AccountSectionNavButton'

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
`

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const LoyaltyCardLinkContainer = styled(AccountSectionNavButton)<{
  loyaltyCardColor: string
  singleBasketCard: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  background-color: ${({ loyaltyCardColor }) => `${loyaltyCardColor}20`};

  ${({ singleBasketCard, loyaltyCardColor }) =>
    singleBasketCard
      ? css`
          height: 100px;
          border: 2px solid ${({ theme }) => theme.colors.siteBackground};
          &:hover {
            border: 2px solid ${loyaltyCardColor};
          }
        `
      : css`
          height: 96px;
          margin-right: 20px;
          border: 2px solid ${({ theme }) => theme.colors.siteBackground};
          min-width: 340px;
          &:hover {
            border: 2px solid ${loyaltyCardColor};
          }
        `}
`

export const LoyaltyCardInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 18px 32px 18px 24px;
`

export const LoyaltyCardTextContainer = styled.div`
  margin-top: 12px;
  width: 100%;
`

export const LoyaltyCardAmountBold = styled.div`
  font-weight: 700;
  display: inline;
`

export const LoyaltyCardText = styled.div<{ isTermsPage: boolean }>`
  flex-direction: row;
  font-size: 14px;
  white-space: ${({ isTermsPage }) => (isTermsPage ? 'prewrap' : 'nowrap')};
  line-height: 18px;
  text-align: left;
`

export const LoyaltyCardTitle = styled.div`
  flex-direction: row;
  font-size: 18px;
  white-space: nowrap;
  line-height: 18px;
  font-weight: 700;
`

export const LoyaltyCardIconAndTextContainer = styled.div`
  display: flex;
  flex-direction row;
  align-items: center;
  height: 100%;
`

export const DescriptionAndStampsContainer = styled.div<{
  isTermsPage: boolean
}>`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  justify-content: space-between;
  height: ${({ isTermsPage }) => (isTermsPage ? '110%' : '120%')};
  width: 80%;
  align-items: flex-start;
`

export const StampAndProgressMeterContainer = styled.div<{
  canStampBeEarned: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  opacity: ${({ canStampBeEarned }) => (canStampBeEarned ? '1.0' : '0.4')};
`
export const StampsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const Stamp = styled.div<{
  hasStampBeenUsed: boolean
  loyaltyCardColor: string
  isOutletList: boolean
  smallStamp: boolean
}>`
  display: flex;
  flex-direction: row;
  min-width: 21px;
  max-width: 21px;
  height: 21px;
  z-index: 0;

  ${({ isOutletList, smallStamp }) =>
    (isOutletList || smallStamp) &&
    css`
      min-width: 18px;
      height: 18px;
    `}

  background-color: ${({ hasStampBeenUsed, loyaltyCardColor }) =>
    hasStampBeenUsed ? `${loyaltyCardColor}` : 'white'};
  justify-content: flex-end;
  border-radius: 24px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`

export const StampAnimation = styled.div<{
  showAnimation: boolean
  loyaltyCardColor: string
  isOutletList: boolean
  smallStamp: boolean
  singleStamp: boolean
}>`
  display: flex;
  min-width: 600%;
  height: 21px;

  ${({ isOutletList, smallStamp }) =>
    (isOutletList || smallStamp) &&
    css`
      height: 18px;
    `}

  align-items: center;
  justify-content: center;
  border-radius: 24px;

  ${({ showAnimation, singleStamp }) => {
    if (showAnimation)
      return css`
        animation: fill ${singleStamp ? '8s' : '4s'}
            cubic-bezier(0.42, 0, 0.58, 1) infinite,
          fadeOut ${singleStamp ? '8s' : '4s'} infinite;
        width: ${singleStamp ? '21px' : '100%'};
        z-index: 1;
      `
  }};

  background: linear-gradient(
    to right,
    ${({ loyaltyCardColor }) => `${loyaltyCardColor}`} 100%,
    transparent 100%
  );
  background-size: 0% 100%;
  background-repeat: no-repeat;

  @keyframes fill {
    0% {
      background-size: 0% 0%;
    }
    50% {
      background-size: 0% 50%;
    }
    100% {
      background-size: 50% 100%;
    }
  }

  @keyframes fadeOut {
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const StampProgressMeter = styled.div<{
  hasStampBeenUsed: boolean
  loyaltyCardColor: string
}>`
  width: 100%;
  margin-left: 0;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  height: 5px;
  background-color: ${({ hasStampBeenUsed, loyaltyCardColor }) =>
    hasStampBeenUsed ? `${loyaltyCardColor}` : 'white'};
`

export const StampProgressMeterAnimation = styled.div<{
  loyaltyCardColor: string
  showAnimation: boolean
}>`
  width: 120%;
  margin-left: 0;
  height: 5px;

  animation: ${({ showAnimation }) =>
    showAnimation
      ? `fill 4s ease-in-out infinite, fadeOut 4s infinite;`
      : `none`};
  background: linear-gradient(
    to right,
    ${({ loyaltyCardColor }) => `${loyaltyCardColor}`} 100%,
    transparent 100%
  );

  background-size: 0% 100%;
  background-repeat: no-repeat;

  @keyframes fill {
    0% {
      background-size: 0% 100%;
    }
    100% {
      background-size: 200% 100%;
    }
  }

  @keyframes fadeOut {
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const LoyaltyCardImage = styled.div<{
  imageUrl: string
  isTermsPage: boolean
  scale: number
  canStampBeEarned: boolean
}>`
  background-size: cover;

  ${({ isTermsPage }) =>
    isTermsPage
      ? css`
          min-width: 87px;
          min-height: 87px;
        `
      : css`
          min-width: 57px;
          min-height: 57px;
        `}

  background-color: grey;
  border-radius: 30px;
  opacity: ${({ canStampBeEarned }) => (canStampBeEarned ? '1.0' : '0.4')};

  position: relative;
  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};
  clip-path: url(#scalloped-mask-${({ scale }) => scale});
`
export const LoyaltyCardSliderContainer = styled.div<{
  singleBasketCard?: boolean
}>`
  margin-bottom: 18px;

  ${({ singleBasketCard }) =>
    singleBasketCard &&
    css`
      .swiper,
      .swiper-wrapper,
      .swiper-slide {
        width: 100% !important;
      }
    `}
`

export const LoyaltyCardName = styled.p`
  font-weight: 700;
  font-size: 24px;
  display: flex;
  justify-content: center;
`
