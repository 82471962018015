import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { UnstyledButton } from '@src/components/Button/Button'

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
  background-color: #f6f6f9;
`

export const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
`
export const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`

export const TextList = styled.div`
  margin-right: 4px;
`

export const StyledLink = styled(Link)`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export const LoyaltyCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoyaltyInfoContainer = styled.div`
  margin-bottom: 24px;
  border: 2px solid #ebecf0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`
export const LoyaltyCardHowManyStampsAwayText = styled.div`
  flex-direction: row;
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 18px;
  text-align: left;
  margin-top: 8px;
`

export const StampedAmount = styled.span`
  flex: 1;
  text-align: right;
`

export const StampedOrderNumber = styled.span`
  flex: 1;
  text-align: left;
`

export const StampedOrderLink = styled(UnstyledButton)`
  flex: 1;
  display: flex;
  &:hover {
    text-decoration: underline;
  }
`
