import React, { useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'

import { AllergenTags } from '@src/components/AllergenTags/AllergenTags'
import {
  CouponContainer,
  StyledCouponContainer,
  StyledCouponSVG,
} from '@src/components/CheckoutModal/Checkout/OrderSummary/OrderSummary.styles'
import { UncontrolledTextInput } from '@src/components/Inputs/TextInput/TextInput'
import { ItemCounter } from '@src/components/ItemCounter/ItemCounter'
import { SpicyChiliSVG } from '@src/components/SVGS/SpicyChiliSVG'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { BasketItemWithPrices } from '@src/hooks/useTotals/useBasketTotals'

import {
  AllergenContainer,
  Container,
  CounterContainer,
  EditButton,
  EditSingleNoteContainer,
  InnerTitleContainer,
  ItemName,
  ItemPrice,
  LeftContainer,
  OptionItemContainer,
  OuterContainer,
  SingleItemNote,
  SingleItemNoteOuterContainer,
  StyledCancelSVG,
  TitleContainer,
} from './BasketItem.styles'
import { OptionItem } from './OptionItem'

type SingleItemProps = {
  basketItemWithPrices: BasketItemWithPrices
  allowSingleItemOrderNotes: boolean
  setRemoveItemIdToConfirm: React.Dispatch<React.SetStateAction<null | string>>
}

export const BasketItem: React.FC<SingleItemProps> = ({
  basketItemWithPrices,
  allowSingleItemOrderNotes,
  setRemoveItemIdToConfirm,
}) => {
  const { patchItem, updateQuantity } = useBasketItems()
  const marketplace = useMarketplace()
  const { basketItem, outletMenuItem } = basketItemWithPrices
  const { id: basketItemId, singleItemNotes } = basketItem
  const {
    allergens,
    spiceLevel,
    name,
    price,
    ageRestricted,
    menuItemId,
    maxPurchaseQuantity,
  } = outletMenuItem
  const { t } = useTranslation('checkout')
  const formatCurrency = useFormatCurrency()

  const [isEditingSingleNote, setIsEditingSinglenote] = useState(false)
  const [note, setNote] = useState(singleItemNotes)

  const handleNoteAction = (
    action: 'save' | 'remove' | 'edit',
    value?: string
  ) => {
    if (action === 'edit') {
      setNote(value || '')
    } else if (action === 'save') {
      patchItem({
        basketItemId,
        patchData: { singleItemNotes: note },
      })
      setIsEditingSinglenote(false)
    } else if (action === 'remove') {
      patchItem({
        basketItemId,
        patchData: { singleItemNotes: '' },
      })
      setNote('')
    }
  }

  const handleAddQuantity = () => {
    updateQuantity({
      basketItemId,
      quantity: basketItemWithPrices.basketItem.quantity + 1,
    })
    ReactPixel.track('AddToCart', {
      content_ids: [menuItemId],
      content_type: 'product',
      content_name: name,
      value: price,
      currency: marketplace.country.currency.iso4217,
    })
  }

  const handleRemoveQuantity = () => {
    if (basketItemWithPrices.basketItem.quantity === 1) {
      setRemoveItemIdToConfirm(basketItemId)
    } else {
      updateQuantity({
        basketItemId,
        quantity: basketItemWithPrices.basketItem.quantity - 1,
      })
    }
  }

  const isMaxQuantityReached =
    maxPurchaseQuantity !== null &&
    basketItemWithPrices.basketItem.quantity >= maxPurchaseQuantity

  return (
    <OuterContainer>
      <Container>
        <LeftContainer>
          <TitleContainer>
            <InnerTitleContainer>
              <ItemName>{name}</ItemName>
              {spiceLevel > 0 &&
                Array.from({ length: spiceLevel }).map((_, index) => (
                  <SpicyChiliSVG width={15} height={15} key={index} />
                ))}
            </InnerTitleContainer>
            <ItemPrice>
              {basketItemWithPrices.isAddOnItem ? (
                <CouponContainer>
                  <StyledCouponContainer>
                    <StyledCouponSVG
                      id="offer-item"
                      title={t('order_summary.offer_item')}
                      desc={t('order_summary.offer_item_desc')}
                    />
                  </StyledCouponContainer>
                  {formatCurrency(basketItemWithPrices.totalPrice)}
                </CouponContainer>
              ) : (
                formatCurrency(basketItemWithPrices.totalPrice)
              )}
            </ItemPrice>
          </TitleContainer>
          {allergens && (
            <AllergenContainer>
              <AllergenTags
                iconView
                allergens={allergens}
                ageRestricted={!!ageRestricted}
              />
            </AllergenContainer>
          )}
          {basketItemWithPrices.selectedOptionItems.length > 0 && (
            <OptionItemContainer>
              {basketItemWithPrices.selectedOptionItems.map(optionItem => (
                <OptionItem
                  key={optionItem.id}
                  optionItem={optionItem}
                  quantity={basketItemWithPrices.basketItem.quantity}
                />
              ))}
            </OptionItemContainer>
          )}
        </LeftContainer>

        <CounterContainer>
          <ItemCounter
            dataTooltipId="page-wrapper-tooltip"
            price={price}
            count={basketItemWithPrices.basketItem.quantity}
            handleAdd={handleAddQuantity}
            handleRemove={handleRemoveQuantity}
            allowAddItem={!isMaxQuantityReached}
            addItemText={
              isMaxQuantityReached
                ? t('reached_max_purchase_quantity')
                : undefined
            }
          />
        </CounterContainer>
      </Container>

      {/* Only if single item notes are allowed */}
      {allowSingleItemOrderNotes && singleItemNotes ? (
        <SingleItemNoteOuterContainer>
          {isEditingSingleNote ? (
            <EditSingleNoteContainer>
              <UncontrolledTextInput
                value={note}
                onChange={(event: { target: { value: string } }) => {
                  const { value } = event.target
                  handleNoteAction('edit', value)
                }}
                onBlur={() => {
                  handleNoteAction('save')
                  setIsEditingSinglenote(false)
                }}
                label={t('edit_single_item_note')}
                touched
                required={false}
                name="editSpecialInstructions"
                focusOnLoad
              />
            </EditSingleNoteContainer>
          ) : (
            <>
              <SingleItemNote>
                <StyledCancelSVG
                  title={t('remove_item_note')}
                  id="remove_note"
                  onClick={() => handleNoteAction('remove')}
                />
                <p>{note}</p>
              </SingleItemNote>
              <EditButton onClick={() => setIsEditingSinglenote(true)}>
                {t('edit')}
              </EditButton>
            </>
          )}
        </SingleItemNoteOuterContainer>
      ) : null}
    </OuterContainer>
  )
}
