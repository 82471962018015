import React from 'react'

import { CollectionDatePicker } from '@src/components/DatePicker/CollectionDatePicker'
import { DeliveryDatePicker } from '@src/components/DatePicker/DeliveryDatePicker'
import { ServicePopover } from '@src/components/ServicePopover/ServicePopover'
import {
  MobileNavigationPopoverContent,
  MobileNavigationPopoverContentWrapper,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { SingleOutletWithDeliveryWindows } from '@src/components/SingleOutlet/types'
import { PopoverFooter } from '@src/components/SiteHeader/ServiceNavigation/Popovers/PopoverFooter'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { ExtendedNonBasketOutletFulfilment } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/extendData/types'

export const TimePopover: React.FC<{
  outlet: Pick<
    SingleOutletWithDeliveryWindows,
    | 'closedUntil'
    | 'prepTime'
    | 'ASAPDeliveryDuration'
    | 'openingTimesArray'
    | 'isOpen'
    | 'asapAllowed'
    | 'collectionPreorderTimes'
    | 'deliveryPreorderWindows'
    | 'daysOfferedInAdvanceMinDelivery'
    | 'daysOfferedInAdvanceMaxDelivery'
    | 'daysOfferedInAdvanceMinCollection'
    | 'daysOfferedInAdvanceMaxCollection'
  >
  timePopoverOpen: boolean
  setTimePopoverOpen: (val: boolean) => void
  setIsSecondaryPopoverOpen?: (val: boolean) => void
  currentFulfilment: ExtendedNonBasketOutletFulfilment['currentFulfilment']
}> = ({
  outlet,
  timePopoverOpen,
  setTimePopoverOpen,
  setIsSecondaryPopoverOpen,
  currentFulfilment,
}) => {
  return (
    <ServicePopover
      open={timePopoverOpen}
      onClose={() => {
        setTimePopoverOpen(false)
        setIsSecondaryPopoverOpen?.(false)
      }}
      headerColor="#fff"
      backgroundColor={'#fff'}
      maxWidth={584}
      showBackdrop={false}
    >
      <MobileNavigationPopoverContentWrapper>
        <MobileNavigationPopoverContent>
          {currentFulfilment.narrowType ===
            NarrowFulfilmentMethodInputType.DELIVERY && (
            <DeliveryDatePicker
              outletData={outlet}
              currentFulfilment={currentFulfilment}
            />
          )}
          {currentFulfilment.narrowType ===
            NarrowFulfilmentMethodInputType.COLLECTION && (
            <CollectionDatePicker
              outletData={outlet}
              currentFulfilment={currentFulfilment}
            />
          )}
        </MobileNavigationPopoverContent>
      </MobileNavigationPopoverContentWrapper>
      <PopoverFooter
        setIsSecondaryPopoverOpen={setIsSecondaryPopoverOpen}
        setPopoverOpen={setTimePopoverOpen}
      />
    </ServicePopover>
  )
}
