import { capitalize } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { LocationType } from '@src/graphql-types'
import { useLazyCustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import { useMarketplaceDeliveryZonesLazyQuery } from '@src/hooks/sharedQueries/useMarketplaceDeliveryZones/useMarketplaceDeliveryZones'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'

import {
  BackButtonContainer,
  NavigationCaption,
} from './BackToOutletListLink.styles'

import { BackButton } from '../BackButton/BackButton'

export const BackToOutletListLink: React.FC<{
  showContainer: boolean
  showText?: boolean
}> = ({ showContainer, showText = true }) => {
  const fulfilmentFilter = useFulfilmentFilter()
  const marketplace = useMarketplace()
  const { t } = useTranslation(['copy', 'common'])
  const navigate = useNavigate()

  const [
    deliveryZonesQuery,
    { loading: deliveryZonesLoading, data: deliveryZonesData },
  ] = useMarketplaceDeliveryZonesLazyQuery()
  const [
    savedAddressesQuery,
    { loading: savedAddressesLoading, data: savedAddressesData },
  ] = useLazyCustomerDetailsAndAddressesQuery()

  // fetch delivery zones or saved addresses on load if applicable
  useEffect(() => {
    if (
      fulfilmentFilter.data.where.location.type === LocationType.DELIVERY_ZONE
    ) {
      void deliveryZonesQuery()
    }
    if (
      showText &&
      fulfilmentFilter.data.where.location.type === LocationType.ADDRESS
    ) {
      void savedAddressesQuery()
    }
  }, [
    deliveryZonesQuery,
    fulfilmentFilter.data.where.location.type,
    savedAddressesQuery,
    showText,
  ])

  // create function for navigating to correct path
  const navigateBack = () => {
    if (
      fulfilmentFilter.data.where.location.type === LocationType.DELIVERY_ZONE
    ) {
      const { zoneId } = fulfilmentFilter.data.where.location
      void deliveryZonesQuery({
        onCompleted: data => {
          const deliveryZone = data.deliveryZonesByMarketplaceId.find(
            zone => zone.id === zoneId
          )
          if (deliveryZone) {
            return navigate(-1)
          }
          return navigate(-1)
        },
      })
    }

    return navigate(-1)
  }

  // get correct text for the back button based on selected fulfilment "where"
  const backToOutletsText = useMemo(() => {
    if (!showText) {
      return ''
    }

    // delivery zone
    if (
      fulfilmentFilter.data.where.location.type === LocationType.DELIVERY_ZONE
    ) {
      const { zoneId } = fulfilmentFilter.data.where.location
      const deliveryZone = (
        deliveryZonesData?.deliveryZonesByMarketplaceId || []
      ).find(zone => zone.id === zoneId)
      if (!deliveryZone) {
        return t('back_to_outlets', {
          urlPath: capitalize(marketplace.urlPath),
          ns: 'copy',
        })
      }
      return t('back_to_outlets_near', {
        urlPath: capitalize(marketplace.urlPath),
        destination: capitalize(deliveryZone.name),
        ns: 'copy',
      })
    }

    // saved address
    if (fulfilmentFilter.data.where.location.type === LocationType.ADDRESS) {
      const { addressId } = fulfilmentFilter.data.where.location
      const address = (
        savedAddressesData?.customerDetails?.deliveryAddresses || []
      ).find(address => address.id === addressId)
      if (!address) {
        return t('back_to_outlets', {
          urlPath: capitalize(marketplace.urlPath),
          ns: 'copy',
        })
      }
      return t('back_to_outlets_near', {
        urlPath: capitalize(marketplace.urlPath),
        destination: address?.name,
        ns: 'copy',
      })
    }

    // postcode
    if (fulfilmentFilter.data.where.location.type === LocationType.POSTCODE) {
      const { postAndCountryCode } = fulfilmentFilter.data.where.location
      return t('back_to_outlets_near', {
        urlPath: capitalize(marketplace.urlPath),
        destination: postAndCountryCode.postcode,
        ns: 'copy',
      })
    }

    // your location
    if (
      fulfilmentFilter.data.where.location.type === LocationType.COORDINATES
    ) {
      return t('back_to_outlets_near_you', {
        urlPath: capitalize(marketplace.urlPath),
        destination: t('near_me', { ns: 'common' }),
        ns: 'copy',
      })
    }

    // fallback
    return t('back_to_outlets', {
      urlPath: capitalize(marketplace.urlPath),
    })
  }, [
    t,
    deliveryZonesData?.deliveryZonesByMarketplaceId,
    fulfilmentFilter.data.where.location,
    marketplace.urlPath,
    savedAddressesData?.customerDetails?.deliveryAddresses,
    showText,
  ])

  const Container = showContainer ? BackButtonContainer : React.Fragment
  return (
    <Container>
      <BackButton
        onClick={navigateBack}
        disabled={deliveryZonesLoading || savedAddressesLoading}
      >
        <NavigationCaption className={showText ? undefined : 'sr-only'}>
          {backToOutletsText}
        </NavigationCaption>
      </BackButton>
    </Container>
  )
}
