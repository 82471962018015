import { useLazyQuery } from '@apollo/client'
import { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  CheckoutVerifyVoucherCodeDocument,
  CheckoutVerifyVoucherCodeQuery,
  CheckoutVerifyVoucherCodeQueryVariables,
} from '@src/components/CheckoutModal/BasketSummary/DiscountCodeSection/queries/__generated__/CheckoutVerifyVoucherCode.graphql-interface'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { apolloErrorParser } from '@src/utils/apolloErrorParser/apolloErrorParser'

type DiscountCodeHookParams = {
  basketSubTotal: number
  outletId: string
  marketplaceId: string
  onDiscountApplied: (id: string) => void
}

type DiscountCodeHookResult = {
  discountError: string | undefined
  discountCodeOpen: boolean
  isVoucherExceedingBasketSubtotal: boolean
  setIsVoucherExceedingBasketSubtotal: (open: boolean) => void
  loading: boolean
  setDiscountCodeOpen: (open: boolean) => void
  handleSubmit: (values: { discount: string }) => Promise<void>
}

export const useDiscountCode = ({
  basketSubTotal,
  outletId,
  marketplaceId,
  onDiscountApplied,
}: DiscountCodeHookParams): DiscountCodeHookResult => {
  const { t } = useTranslation('discounts')
  const [discountError, setDiscountError] = useState<string | undefined>('')
  const [discountCodeOpen, setDiscountCodeOpen] = useState(false)
  const [
    isVoucherExceedingBasketSubtotal,
    setIsVoucherExceedingBasketSubtotal,
  ] = useState(false)
  const [currentDiscountAmount, setCurrentDiscountAmount] = useState<
    number | null
  >(null)

  const [verifyVoucherCode, { loading }] = useLazyQuery<
    CheckoutVerifyVoucherCodeQuery,
    CheckoutVerifyVoucherCodeQueryVariables
  >(CheckoutVerifyVoucherCodeDocument)
  const formatCurrency = useFormatCurrency()

  useEffect(() => {
    if (discountError) {
      const timeoutId = setTimeout(() => {
        setDiscountError('')
      }, 6000)
      return () => clearTimeout(timeoutId)
    }
  }, [discountError])

  useEffect(() => {
    if (currentDiscountAmount !== null) {
      setIsVoucherExceedingBasketSubtotal(
        basketSubTotal < currentDiscountAmount
      )
    }
  }, [basketSubTotal, currentDiscountAmount])

  const handleSubmit = useCallback(
    async (values: { discount: string }) => {
      if (!values.discount) {
        setDiscountError(t('invalid_discount'))
        return
      }

      const { data, error } = await verifyVoucherCode({
        variables: {
          key: values.discount,
          marketplaceId,
          outletId,
        },
      })

      const parsedErrors = error ? apolloErrorParser(error) : null

      if (!data || parsedErrors?.genericClientErrors?.length) {
        setDiscountError(
          parsedErrors?.genericClientErrors?.[0]?.message || t('discount_error')
        )
        return
      }

      if (data) {
        const minimumSubtotalGross =
          data.isVoucherValid.minimumSubtotalGross || 0
        if (basketSubTotal < minimumSubtotalGross) {
          setDiscountError(
            t('discount_minimum_not_met_error', {
              minimumSubtotalGross: formatCurrency(minimumSubtotalGross),
            })
          )
          return
        }
        setCurrentDiscountAmount(data.isVoucherValid.discountAmount || null)
        if (
          data.isVoucherValid.discountAmount &&
          basketSubTotal < data.isVoucherValid.discountAmount
        ) {
          setIsVoucherExceedingBasketSubtotal(true)
        }

        onDiscountApplied(data.isVoucherValid.id)
        toast.success(t('discount_successfully_applied'))
        setDiscountCodeOpen(false)
      }
    },
    [
      basketSubTotal,
      formatCurrency,
      marketplaceId,
      outletId,
      t,
      verifyVoucherCode,
    ]
  )

  return {
    discountError,
    discountCodeOpen,
    isVoucherExceedingBasketSubtotal,
    setIsVoucherExceedingBasketSubtotal,
    loading,
    setDiscountCodeOpen,
    handleSubmit,
  }
}
