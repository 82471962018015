import { ApolloError } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import leftArrow from '@src/images/left-arrow.svg'

import { ErrorContainer, ErrorButton, ErrorBackArrow } from './error.styles'

export const ErrorPage: React.FC<{
  errorMessage?: string
  previousPage?: boolean
  logError?: ApolloError | null
  homePage?: boolean
}> = ({
  errorMessage = 'Oh no! Something went wrong.',
  previousPage,
  homePage = true,
  logError,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (logError && process.env.NODE_ENV === 'development') {
    console.error('Error', logError)
  }
  return (
    <ErrorContainer>
      <h1>{errorMessage}</h1>
      {previousPage ? (
        <ErrorButton onClick={() => navigate(-1)}>
          <ErrorBackArrow src={leftArrow} alt={t('back')} />
          {t('back')}
        </ErrorButton>
      ) : (
        homePage && (
          <ErrorButton onClick={() => navigate('/')}>
            {t('back_to_homepage')}
          </ErrorButton>
        )
      )}
    </ErrorContainer>
  )
}
