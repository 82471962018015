import styled, { css } from 'styled-components'

import { AccountSectionNavButton } from '@src/components/AccountSectionNavButton/AccountSectionNavButton'
import { breakpoints } from '@src/constants/breakpoints'

export const ActiveOrdersContainer = styled.div<{ singleorder: boolean }>`
  overflow-y: hidden;
  margin-bottom: 36px;
  ${({ singleorder }) =>
    !singleorder &&
    css`
      margin-left: -32px;
    `}
`

export const SingleActiveOrderContainer = styled.div<{
  oneActiveOrder: boolean
}>`
  flex-direction: column;
  display: flex;
  cursor: pointer;
  width: 280px;

  @media (min-width: ${breakpoints.desktop}px) {
    margin-right: 48px;
  }

  ${({ oneActiveOrder }) =>
    oneActiveOrder &&
    css`
      width: 280px !important;
      @media (min-width: ${breakpoints.tabletMisc}px) {
        width: 364px !important;
      }
      @media (min-width: ${breakpoints.desktop}px) {
        width: 332px !important;
      }
    `}
`

export const Order = styled(AccountSectionNavButton)<{
  active: boolean
  lastOrder: boolean
  firstOrder: boolean
}>`
  all: unset;
  display: flex;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 2px solid #ebecf0;
  border-radius: 8px;
  margin-right: 16px;
  cursor: pointer;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.mainText};

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brand};
    color: ${({ theme }) => theme.colors.mainText};
    h4 {
      color: ${({ theme }) => theme.colors.brand};
    }
  }

  ${({ lastOrder }) =>
    lastOrder &&
    css`
      margin-right: 32px;
    `}

  ${({ firstOrder }) =>
    firstOrder &&
    css`
      margin-left: 32px;
    `}
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
  align-items: center;
`

export const ArrowContainer = styled.div`
  button {
    margin-left: 8px;
  }
`
