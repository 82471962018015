import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export type LinkProps = Omit<React.ComponentProps<typeof RouterLink>, 'to'> & {
  passthroughQueryParams?: boolean
  to: string | { pathname?: string; state?: any; search?: string }
  dataTestId?: string
}

export const Link: React.FC<LinkProps> = ({
  passthroughQueryParams = true,
  dataTestId,
  ...props
}) => {
  const [pathname, search] =
    typeof props.to === 'string'
      ? props.to.split('?')
      : [props.to.pathname, props.to.search]

  return (
    <RouterLink
      {...props}
      to={{
        pathname,
        search: passthroughQueryParams ? window.location.search : search,
        ...(typeof props.to !== 'string' && props.to),
      }}
      data-test-id={dataTestId}
    />
  )
}
